import  React,{useState} from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import PersonIcon from '@mui/icons-material/Person';
import { userService } from '../services/user.service';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

export default function AccountRegistering() {

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickConfirmShowPassword = () => setShowConfirmPassword((show) => !show);


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    //入力されたID、nickname、PW、確認用PWを変数に代入
    const data = new FormData(event.currentTarget);
    const username = data.get('login_id');
    const nickname = data.get('nickname');
    const password = data.get('password');
    const password_chk = data.get('password_chk');

    //変数の確認用ログ
    console.log({
      ID: username,
      nickname: nickname,
      password: password,
      password_chk: password_chk,
    });

    //ID、nickname、PW、確認用PWを../services/user.service のアカウント登録処理に飛ばす
    if (username && nickname && password && password_chk) {
      userService.addUser(username, nickname, password, password_chk)
        .then(
          //returnされたdataの確認用ログ
          data => {
            console.log({
              deta: data,
            });

            //アカウント追加が成功なら、画面を遷移する(遷移方法はもっと何かあるかも…)
            // loginSuccess(data);
            window.location.href = '/';
          },
          // error => {
          //ログイン失敗時の処理は、現状特になし
          // loginFailure(error);
          // }
        );
    };
  };

  document.title = 'アカウント登録 屋内疑似お散歩レク';

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <PersonIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          アカウント登録
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="login_id"
            label="メールアドレス"
            name="login_id"
            autoComplete="login_id"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="nickname"
            label="ニックネーム"
            name="nickname"
            autoComplete="nickname"
          />
           <FormControl required sx={{ marginTop:"15px",marginBottom:"10px",width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">パスワード</InputLabel>
            <OutlinedInput
              id="password"
              name="password"
              required
              label="パスワード"
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl required sx={{ marginTop:"15px",marginBottom:"10px",width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">パスワード再確認</InputLabel>
            <OutlinedInput
              id="password_chk"
              name="password_chk"
              autoComplete="current-password"
              label="パスワード再確認"
              type={showConfirmPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickConfirmShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <div align="right">
            <Button type="submit"><img src="/static/images/cards/add.png" alt="登録" height="30" /></Button>
          </div>
        </Box>
      </Box>
    </Container>
  );
}