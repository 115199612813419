import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import Box from "@mui/material/Box";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ParticipantTable from "./ParticipantTable";
import { styled } from "@mui/material/styles";
import { userService } from "../services/user.service";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCurrentIdAccount } from "../helpers/auth-header";

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: "0px 10px 10px",
});

const StyledAccordionSummary = styled(AccordionSummary)({
  "& .MuiAccordionSummary-content": {
    margin: "0",
  },
});

const currentAccountId = getCurrentIdAccount()

export default function Participant(props) {
  const {currentUserId} = props;

  const [openInput, setOpenInput] = React.useState(false);
  const [participant, setParticipant] = useState([]);


  useEffect(() => {
    handleCallListParticipant();
  }, [currentUserId]);

  const handleCallListParticipant = (list) => {
      userService
      .getParticipantList({id_account:currentUserId??currentAccountId})
      .then((data) => {
        setParticipant(data.result.items);
      })
      .catch((error) => {
        console.log("error==>", error);
      });
   
  };

  const handleOpenInput = (event) => {
    // event.stopPropagation()
    setOpenInput(true);

  };

  const handleCloseInput = () => {
    setOpenInput(false);
  };

  return (
    <Accordion defaultExpanded disableGutters>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        sx={{ minHeight: 26 }}
        onClick={(event) => event.stopPropagation()}
      >
        <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent:"space-between",
          width:"100%"
        }}
      >
        <Typography>参加者</Typography>
       
        </Box>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <ParticipantTable
          isRoot ={true}
          currentUserId={currentUserId??currentAccountId}
          participant={participant}
          onOpenInput={handleOpenInput}
          openInput={openInput}
          onCloseInput={handleCloseInput}
          callList={handleCallListParticipant}
        />
      </StyledAccordionDetails>
    </Accordion>
  );
}
