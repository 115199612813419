import AWS from 'aws-sdk'
import { commonConstants } from '../constants/common.constants';

const S3_BUCKET = commonConstants.S3_BUCKET;
const REGION = commonConstants.S3_REGION;

AWS.config.update({
  accessKeyId: commonConstants.S3_ACCESS_KEY, //アクセスキー入力
  secretAccessKey: commonConstants.S3_SECRET_ACCESS //シークレットアクセスキー入力
})

export const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
  httpOptions: {
    timeout: 600000
  }
})

// function upload video to s3
export const uploadVideo = (file, fileName, callbackProgress, callbackUrl) => {
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: fileName
    };

    const request = myBucket.putObject(params); // Store the request object

    const progressListener = (evt) => {
      // call back progress bar
      callbackProgress && callbackProgress(Math.round((evt.loaded / evt.total) * 100), cancelUpload);
    };
  
    const completeListener = (err, data) => {
      if (err) {
        return;
      } else if (data) {
        callbackUrl && callbackUrl(fileName);
      }
    };

    const cancelUpload = () => {
      request.removeListener('httpUploadProgress', progressListener);
      request.abort();
    };

    request.on('httpUploadProgress', progressListener);
    request.send(completeListener);

  }


// function move file to orther path s3
export function moveS3File(currentPath, newPath, callBack) {

  const params = {
    ACL: 'public-read',
    Bucket: S3_BUCKET,
    CopySource: `${S3_BUCKET}/${currentPath}`,
    Key: newPath
  };

  myBucket.copyObject(params, function(err, data) {
    if (err) {
      callBack &&callBack({success:false})
    } else {
      callBack &&callBack({success:true})
    }
  });
}


// function delete file s3
export function deleteS3File(currentPath, callBack) {
  const params = {
    ACL: 'public-read',
    Bucket: S3_BUCKET,
    Key: currentPath
  };
  myBucket.deleteObject(params, function(err, data) {
    if (err) {
      callBack && callBack(err)
    } else {
      callBack && callBack(data)
    }
  });
}

// function check file s3
export async function checkS3FileExists(path) {
  const params = {
    Bucket: S3_BUCKET,
    Key: path
  };
  try {
    await myBucket.headObject(params).promise();
    return true;
  } catch (err) {
    if (err.code === 'NotFound') {
      return false;
    }
    throw err;
  }
}
