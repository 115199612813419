import React, { Fragment, useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { formGroupClasses } from '@mui/material';
import { commonConstants } from '../constants/common.constants';

export default function ConfirmDialog(props) {
  const [open, setOpen] = React.useState(false);

  const ConDialog = props.ConDialog
  const errorFlg = props.errorFlg
  const errorMsg = props.errorMsg

  /*const handleClickOpen = () => {
    setOpen(true);
  };*/

  useEffect(() => {

    console.log({
      checheck2: ConDialog
    });

    if (ConDialog){
      setOpen(true);
    }
}, [ConDialog])

  const handleClose = () => {
    setOpen(false);

    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
      // window.location.href = 'http://192.168.0.27:3000/UserManagementApp';
      window.location.href = commonConstants.CLOUDFRONT_URL + 'UserManagementApp';
    } 

   //連続したパスワード変更に手間取った為、変更後は一度ログイン画面に戻す
  };

  return (
    <div>
      {/*<Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
  </Button>*/}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"確認"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {errorFlg ?
              "更新しました。"
            : errorMsg
          }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
