import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { userService } from '../services/user.service';
import { Tooltip } from '@mui/material';

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}),
);

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  // justifyContent: 'flex-end',
  marginLeft: 4,
}));

export default function UserHeader() {

  const [nickName, setNickName] = React.useState(null);

  useEffect(() => {
    userService.getNickName()
    .then(
      data => {
        setNickName(data.result.nickname)
      },
      error => {
         //errorMsg = error;
        console.log({
           //errorMsg: errorMsg,
        });
      }
      );
    }, [])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <img src="/static/images/cards/logo.png" alt="Osampo App" height="50" />
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <InputLabel  style={{ marginRight: 30, backgroundColor: 'transparent', color: 'white' }}>
              {nickName} さん　ようこそ
            </InputLabel>
            <Button href="/" type="submit">
              <Tooltip title="ログアウト">
                <img src="/static/images/cards/logout.png" alt="Logout" height="30" />
              </Tooltip>
              </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      <Main>
        <DrawerHeader />
      </Main>
    </Box>
  );
}
