import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Backdrop, Typography, Box } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import CourseUploadModel from "./CourseUploadModel";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// check video duration
const getVideoDuration = (file) =>
  new Promise((resolve, reject) => {
    const video = document.createElement("video");
    const url = URL.createObjectURL(file);
    video.src = url;
    video.onloadedmetadata = () => {
      const duration = video.duration;
      URL.revokeObjectURL(url);
      resolve(duration);
    };
    video.onerror = (error) => reject(error);
  });

  // check file size
const checkFileSize = (file) => {
  const maxSize = 1024 * 1024 * 1024; // 1GB in bytes
  if (file.size <= maxSize) {
    return true;
  } else {
    return false;
  }
};

export default function CourseInputFileModel(props) {
  const { open, onClose, getContentList, settingContent } = props;
  const inputRef = useRef();
  const [text, setText] = useState("");
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const [video, setVideo] = useState();
  const [duratioVideo, setDurationVideo] = useState(0);

  // handle change file before upload
  const handleChangleFile = (event) => {
    setText("");
    event.preventDefault();
    const file = event.target.files[0];
    if (file.type.startsWith("video/")) {
      // check video duration time
      if (!checkFileSize(file)) {
        setText("アップロードファイルが1GB以下にしてください。");
      } else {
        getVideoDuration(file)
          .then((duration) => {
            // check file duration >=3:30s
            if (duration <= 210) {
              setVideo(event.target.files[0]);
              setDurationVideo(Math.round(duration));
              openModelUpload();
              handleClose();
            } else {
              setText("3分を超える動画はアップロードできません。");
            }
          })
          .catch((error) => {
            console.log("get video duration error", error);
          });
      }
    } else {
      setText("アップロードの動画を選択してください。");
    }
  };

  //   handle select file
  const handleSelectFile = (event) => {
    event.preventDefault();
    inputRef.current.click();
  };

  //   open  model upload process
  const openModelUpload = () => {
    setIsOpenUpload(true);
  };
  //  close  model upload process
  const closeModelUpload = () => {
    setIsOpenUpload(false);
    onClose && onClose();
  };

  // handle close
  const handleClose = () => {
    setText("");
    onClose && onClose();
  };

  // clear video
  const clearVideo = () => {
    setVideo();
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "30px",
            outline: "none",
            borderTop: "1px solid #bdbdbd",
            position: "absolute",
            bottom: "0",
            width: "100vw",
            height: "65vh",
          }}
        >
          <Box
            style={{
              position: "absolute",
              right: "30px",
              top: "30px",
              backgroundColor: "white",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <HighlightOffIcon style={{ fontSize: "40px" , color:"gray"}} />
          </Box>
          <Typography fontSize={"18px"}> 選択</Typography>
          <Box
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: " translate(-50%, -50%)",
              width: "80%",
              height: "100px",
            }}
          >
            <Box
              style={{
                width: "70px",
                height: "70px",
                position: "relative",
                left: "50%",
                top: "0%",
                transform: " translate(-50%, 0%)",
                backgroundColor: "#0ca7f2",
                borderRadius: "50%",
              }}
            >
              <FolderIcon
                style={{
                  fontSize: "50px",
                  margin: "auto",
                  color: "white",
                  cursor: "pointer",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: " translate(-50%, -50%)",
                  cursor: "pointer",
                }}
                onClick={handleSelectFile}
              />
            </Box>
            <Typography
              style={{
                marginTop: "20px",
                textAlign: "center",
                color: text ? "red" : "black",
              }}
            >
              {text ? text : "ファイル"}
            </Typography>

            <input
              type="file"
              onChange={handleChangleFile}
              hidden
              accept="video/mp4"
              ref={inputRef}
            />
          </Box>
        </div>
      </Modal>
{/* model create course */}
      <CourseUploadModel
        open={isOpenUpload}
        onClose={closeModelUpload}
        video={video}
        clearVideo={clearVideo}
        duratioVideo={duratioVideo}
        getContentList={getContentList}
        settingContent={settingContent}
      />
    </div>
  );
}
