import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Login from './components/Login';
import AccountRegistering from './components/AccountRegistering';
import UserManagement from './components/UserManagement';
import RootManagement from './components/RootManagement';
import SideList from './components/SideList';
import AlertDialog from './components/AlertDialog';
import UserManagementApp from './components/UserManagementApp';
import DummyLogin from './components/DummyLogin';
import ResetPassword from './components/ResetPassword';
// import PrivateRoute from './components/PrivateRoute';
import './App.css';


export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/password-reset" component={ResetPassword} />
        <Route exact path="/AccountRegistering" component={AccountRegistering} />
        <Route exact path="/UserManagement" component={UserManagement} />
        <Route exact path="/RootManagement" component={RootManagement} />
        <Route exact path="/SideList" component={SideList} />
        <Route exact path="/AlertDialog" component={AlertDialog} />
        <Route exact path="/UserManagementApp" component={UserManagementApp} />
        <Route exact path="/DummyLogin" component={DummyLogin} />
      </Switch>
    </BrowserRouter>
  );
}