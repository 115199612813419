import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { userService } from "../services/user.service";
import { visuallyHidden } from "@mui/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Modal from "@mui/material/Modal";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@mui/material";

const headCells = [
  {
    key: "name",
    numeric: false,
    disablePadding: true,
    label: "参加者名",
  },
];

const StyledTableCell = styled(TableCell)({
  padding: "5px 10px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#98b5d1",
  },
});

const HoverTypography = styled(Typography)({
  padding: "5px",
  "&:hover": {
    backgroundColor: "#f0f0f0",
    cursor: "pointer",
  },
});

export default function ParticipantTable(props) {
  const {
    participant,
    openInput,
    onOpenInput,
    onCloseInput,
    callList,
    currentUserId,
    isRoot,
    setConDialog,
    setErrorFlg,
    setErrorMsg,
  } = props;
  const [listParticipant, setListParticipant] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [currentContent, setCurrentContent] = useState("");
  const [validate, setValidate] = useState(false);
  const [currentParticipantId, setCurerntParticipantId] = useState(null);

  // Update State
  const [isShow, setIsShow] = useState(false);
  const [isShowInput, setIsShowInput] = useState(false);
  const [currentContentUpdate, setCurrentContentUpdate] = useState("");
  const [validateUpdateInput, setValidateUpdateInput] = useState(false);

  const [isShowModelConfirm, setIsShowwModelConfirm] = useState(false);
  const refShow = useRef(null);

  useEffect(() => {
    // set list participant from props
    setListParticipant(participant);
    handleCloseInput();
  }, [participant]);

  useEffect(() => {
    // help  detect when user click out model
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //   sort table by key
  const createSortHandler = (key) => {
    if (key === orderBy) {
      setOrder(order === "desc" ? "asc" : "desc");
      const listSort = listParticipant.sort((a, b) => {
        if (order === "desc") {
          if (typeof a[key] === "string" && typeof b[key] === "string")
            return a[key].localeCompare(b[key]);
          return a[key] - b[key];
        }
        if (typeof a[key] === "string" && typeof b[key] === "string")
          return b[key].localeCompare(a[key]);
        return b[key] - a[key];
      });
      setListParticipant(listSort);
    } else {
      setOrderBy(key);
    }
  };

  const handleAddToList = () => {
    // validate data before call api
    if (!currentContent) return setValidate(true);
    // call api get list participant
    userService
      .addParticipant({ name: currentContent, id_account: currentUserId })
      .then((data) => {
        if (data?.result?.participant_id) {
          const listAdded = [
            { id: data?.result?.participant_id, name: currentContent },
            ...listParticipant,
          ];
          // set list participant again after success
          setListParticipant(listAdded);
        }
      })
      .catch((error) => {
        setErrorFlg(false);
        setErrorMsg(error);
        setConDialog(true);
      });
      setConDialog(false)

    // close and clear content after add
    setCurrentContent("");
    onCloseInput && onCloseInput();
  };

  // change value add input
  const handleChangeValueInput = (event) => {
    setValidate(false);
    setCurrentContent(event.target.value);
  };

  // change value update input
  const handleChangeValueUpdateInput = (event) => {
    setValidateUpdateInput(false);
    setCurrentContentUpdate(event.target.value);
  };

  // handle close input after add
  const handleCloseInput = () => {
    setCurrentContent("");
    setValidate(false);
    onCloseInput && onCloseInput();
  };

  // close model "button add and delete" and model "update input" when click out
  const handleClickOutside = (event) => {
    // check ref before close model
    if (refShow.current && !refShow.current.contains(event.target)) {
      setCurerntParticipantId(null);
      setIsShow(false);
    }
  };

  //  open model "button add and delete" and set value for "update input" and clear validate
  const handleClickPaticipantIcon = (id) => {
    setCurerntParticipantId(id);
    setIsShow(true);
    setIsShowInput(false);
    setValidateUpdateInput(false);
    const index = listParticipant.findIndex((item) => item.id === id);
    setCurrentContentUpdate(listParticipant[index].name);
  };

  // open model "update input"
  const handleClickUpdate = (id) => {
    setIsShow(false);
    setIsShowInput(true);
  };

  // cancel update value "update input"
  const handleClickCancel = (id) => {
    setIsShow(false);
    setIsShowInput(false);
    setCurerntParticipantId(null);
  };

  // open model comfirm delete participant
  const handleClickDelete = (id) => {
    setIsShow(false);
    setIsShowwModelConfirm(true);
  };

  // cancel delete and close model
  const onCloseConfirmDelete = () => {
    setIsShowwModelConfirm(false);
    setCurerntParticipantId(null);
    setIsShow(false);
  };

  // update and delete participant
  const handleUpdateParticipant = ({ id, name, active }) => {
    if (!currentContentUpdate) return setValidateUpdateInput(true);
    // call api update and delete
    userService
      .updateParticipant({ id_account: currentUserId, name, id, active })
      .then((data) => {
        if (data?.result?.participant_id) {
          // if active ==1 is update
          if (active) {
            // call back list after update and render again
            callList && callList();
          }
          // if active ==0 is delete
          else {
            // remove item suucess in list and set list again
            const index = listParticipant.findIndex((item) => item.id === id);
            listParticipant.splice(index, 1);
            setListParticipant(listParticipant);
            onCloseConfirmDelete();
          }
        }
      })
      .catch((error) => {
        console.log("error update participant", error);
      });
    // set participant is null and close model after success
    setCurerntParticipantId(null);
    setIsShowInput(false);
    setCurrentContentUpdate("");
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Modal
        open={isShowModelConfirm}
        onClose={onCloseConfirmDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            width: 400,
            bgcolor: "background.paper",
            border: "1px solid gray",
            borderRadius: "10px",
            outline: "none",
            p: 1,
          }}
        >
          <Typography
            id="modal-modal-title"
            fontWeight="bold"
            textAlign="center"
            variant="h7"
            component="h4"
            style={{ marginTop: "10px" }}
          >
            参加者をリストから削除します。
          </Typography>
          <Typography
            id="modal-modal-description"
            textAlign="center"
            variant="h7"
            component="h4"
          >
            よろしいですか？
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "70%",
              margin: "50px auto 10px",
            }}
          >
            <Button
              onClick={() =>
                handleUpdateParticipant({
                  id: currentParticipantId,
                  name: currentContentUpdate,
                  active: 0,
                })
              }
              style={{padding:"0px"}}
            >
              {/* 削除する */}
              <Tooltip title="削除する">
                <img
                  className="course_button_img"
                  src="/static/images/cards/btn_confirm_delete_course.png"
                  alt="ok"
                  height="40px"
                  width="auto"
                />
              </Tooltip>
            </Button>
            <Button onClick={onCloseConfirmDelete} style={{padding:"0px"}}>
              {/* キャンセル */}
              <Tooltip title="キャンセル">
                <img
                  className="course_button_img"
                  src="/static/images/cards/btn_cancel.png"
                  alt="cancel"
                  height="40px"
                  width="auto"
                />
              </Tooltip>
            </Button>
          </Box>
        </Box>
      </Modal>
      <Paper sx={{ width: "100%" }}>
        {isRoot ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginRight: "15px",
            }}
          >
            <Button sx={{ paddingBottom: "0px", cursor: "pointer", }}>
              {/* 参加者を追加 */}
              <Tooltip title=" 参加者を追加">
                <img
                  className=""
                  src="/static/images/cards/btn_create_participant.png"
                  alt="btn_create_participant"
                  height="30px"
                  width="auto"
                  onClick={() => onOpenInput && onOpenInput()}
                />
              </Tooltip>
            </Button>
          </Box>
        ) : (
          ""
        )}
        <TableContainer sx={{ height: 230 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <StyledTableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={"normal"}
                    style={{ paddingLeft: "50px" }}
                    sortDirection={orderBy === headCell.key ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.key}
                      direction={orderBy === headCell.key ? order : "asc"}
                      onClick={() => createSortHandler(headCell.key)}
                    >
                      {headCell.label}
                      {orderBy === headCell.key ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* model add input */}
              {openInput ? (
                <Box>
                  <Box
                    style={{
                      display: "flex",
                      margin: "5px 0px",
                      paddingLeft: "50px",
                      alignItems:"center"
                    }}
                  >
                    <Box>
                      <input
                        maxLength={255}
                        style={{
                          padding: "9px 10px",
                          borderRadius: "4px",
                          width: "350px",
                          outline: "none",
                          border: validate
                            ? "1px solid #f44336"
                            : "1px solid  #9e9e9e",
                          fontSize: "16px",
                        }}
                        placeholder="参加者名を入力してください"
                        value={currentContent}
                        onChange={handleChangeValueInput}
                      />
                    </Box>
                    <Button
                      style={{ marginLeft: "10px", height: "35px" , padding:"0px"}}
                      onClick={handleAddToList}
                    >
                      {/* 登録 */}
                      <Tooltip title="登録する">
                        <img
                          className=""
                          src="/static/images/cards/btn_register.png"
                          alt="btn_register"
                          height="36px"
                          width="auto"
                        />
                      </Tooltip>
                    </Button>
                    <Button
                      style={{
                        padding: "0px",
                        marginLeft: "10px",
                      }}
                      onClick={handleCloseInput}
                    >
                      <Tooltip title="キャンセル">
                        <img
                          src="/static/images/cards/btn_cancel.png"
                          alt="cancel"
                          height="36px"
                          width="auto"
                        />
                      </Tooltip>
                    </Button>
                  </Box>
                  <Box
                    style={{
                      height: "1px",
                      backgroundColor: "#e0e0e0",
                      width: "100%",
                    }}
                  ></Box>
                </Box>
              ) : (
                ""
              )}
              {/* render list participant */}
              {listParticipant &&
                listParticipant?.map((data) => {
                  return (
                    <TableRow hover tabIndex={-1} key={data.id}>
                      <StyledTableCell
                        style={{ paddingLeft: "50px", cursor: "pointer" }}
                        align="left"
                      >
                        {/* input for update */}
                        {currentParticipantId === data.id && isShowInput ? (
                          <Box>
                            <Box
                              style={{
                                display: "flex",
                                margin: "0px 0px",
                                alignItems:"center"
                              }}
                            >
                              <Box>
                                <input
                                  maxLength={255}
                                  style={{
                                    padding: "9px 10px",
                                    borderRadius: "4px",
                                    width: "350px",
                                    outline: "none",
                                    border: validateUpdateInput
                                      ? "1px solid #f44336"
                                      : "1px solid  #9e9e9e",
                                    fontSize: "16px",
                                  }}
                                  placeholder="参加者名を入力してください"
                                  value={currentContentUpdate}
                                  onChange={handleChangeValueUpdateInput}
                                />
                              </Box>
                              <Button
                                style={{ marginLeft: "10px", height: "35px", padding:"0px" }}
                                onClick={() =>
                                  handleUpdateParticipant({
                                    id: data.id,
                                    name: currentContentUpdate,
                                    active: 1,
                                  })
                                }
                              >
                                {/* 登録 */}
                                <Tooltip title="登録する">
                                  <img
                                    className=""
                                    src="/static/images/cards/btn_register.png"
                                    alt="btn_register"
                                    height="36px"
                                    width="auto"
                                  />
                                </Tooltip>
                              </Button>
                              <Button
                                style={{
                                  padding: "0px",
                                  marginLeft: "10px",
                                }}
                                onClick={handleClickCancel}
                              >
                                <Tooltip title="キャンセル">
                                  <img
                                    src="/static/images/cards/btn_cancel.png"
                                    alt="cancel"
                                    height="36px"
                                    width="auto"
                                  />
                                </Tooltip>
                              </Button>
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography>{data.name}</Typography>
                            <Box
                              style={{
                                position: "relative",
                                marginLeft: "10px",
                              }}
                            >
                              <ExpandMoreIcon
                                onClick={() =>
                                  handleClickPaticipantIcon(data.id)
                                }
                                style={{
                                  fontSize: "16px",
                                  rotate:
                                    currentParticipantId === data.id
                                      ? "180deg"
                                      : "",
                                }}
                              />
                              {currentParticipantId === data.id && isShow ? (
                                <Box
                                  style={{
                                    position: "absolute",
                                    left: -100,
                                    top: 20,
                                    width: "120px",
                                    backgroundColor: "white",
                                    border: "1px solid gray",
                                    textAlign: "center",
                                    color: "gray",
                                    zIndex: 100,
                                  }}
                                  ref={refShow}
                                >
                                  <HoverTypography
                                    onClick={() => handleClickUpdate(data.id)}
                                  >
                                    編集
                                  </HoverTypography>
                                  <HoverTypography
                                    onClick={() => handleClickDelete(data)}
                                  >
                                    削除
                                  </HoverTypography>
                                </Box>
                              ) : (
                                ""
                              )}
                            </Box>
                          </Box>
                        )}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
