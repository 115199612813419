import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { userService } from "../services/user.service";
import { visuallyHidden } from "@mui/utils";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@mui/material";

const headCellsRoot = [
  {
    key: "id",
    numeric: "left",
    disablePadding: true,
    label: "ID",
  },
  {
    key: "public_start",
    numeric: "left",
    disablePadding: true,
    label: "揭載開始日",
  },
  {
    key: "public_end",
    numeric: "left",
    disablePadding: true,
    label: "揭載終了日",
  },
  {
    key: "body",
    numeric: "left",
    disablePadding: true,
    label: "タイトル",
  },
  {
    key: "edit",
    numeric: "center",
    disablePadding: true,
    label: "編集",
  },
  {
    key: "delete",
    numeric: "center",
    disablePadding: true,
    label: "削除",
  },
];

const headCells = [
  {
    key: "id",
    numeric: "left",
    disablePadding: true,
    label: "ID",
  },
  {
    key: "body",
    numeric: "left",
    disablePadding: true,
    label: "タイトル",
  },
];

const StyledTableCell = styled(TableCell)({
  padding: "5px 10px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#98b5d1",
  },
});

export default function NotificationTable(props) {
  const { notification, callList, callDetail, isRoot, openModel } = props;
  const [listNotification, setListNotification] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [isShowModelConfirm, setIsShowModelConfirm] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState("");
  // Update State

  useEffect(() => {
    // set list Notification from props
    setListNotification(notification);
  }, [notification]);

  //   sort table by key
  const createSortHandler = (key) => {
    if (key === orderBy) {
      setOrder(order === "desc" ? "asc" : "desc");
      const listSort = listNotification.sort((a, b) => {
        if (order === "desc") {
          if (typeof a[key] === "string" && typeof b[key] === "string")
            return a[key].localeCompare(b[key]);
          return a[key] - b[key];
        }
        if (typeof a[key] === "string" && typeof b[key] === "string")
          return b[key].localeCompare(a[key]);
        return b[key] - a[key];
      });
      setListNotification(listSort);
    } else {
      setOrderBy(key);
    }
  };

  // handle get detail callback
  const hanldeUpdate = (data) => {
    callDetail && callDetail(data);
  };
  // hanlde Click Button Delete
  const hanldeClickButtonDelete = (id) => {
    setIsShowModelConfirm(true);
    setCurrentDeleteId(id);
  };
  // close model comfirm
  const onCloseConfirmDelete = () => {
    setIsShowModelConfirm(false);
    setCurrentDeleteId("");
  };

  const handleDelete = () => {
    userService
      .deleteNotificaion({
        news_id: currentDeleteId,
        is_deleted: 1,
      })
      .then((data) => {
        // call back get list account again
        onCloseConfirmDelete();
        callList && callList();
      })
      .catch((error) => {
        console.log("error delete notification=>", error);
      });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Modal
        open={isShowModelConfirm}
        onClose={onCloseConfirmDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            width: 400,
            bgcolor: "background.paper",
            border: "1px solid gray",
            borderRadius: "10px",
            outline: "none",
            p: 1,
          }}
        >
          <Typography
            id="modal-modal-title"
            fontWeight="bold"
            textAlign="center"
            variant="h7"
            component="h4"
            style={{ marginTop: "10px" }}
          >
            お知らせをリストから削除します。
          </Typography>
          <Typography
            id="modal-modal-description"
            textAlign="center"
            variant="h7"
            component="h4"
          >
            よろしいですか？
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "70%",
              margin: "50px auto 10px",
            }}
          >
            <Button onClick={handleDelete} style={{padding:"0px"}}>
              {/* 削除する */}
              <Tooltip title="削除する">
                <img
                  className="course_button_img"
                  src="/static/images/cards/btn_confirm_delete_course.png"
                  alt="ok"
                  height="40px"
                  width="auto"
                />
              </Tooltip>
            </Button>
            <Button onClick={onCloseConfirmDelete} style={{padding:"0px"}}>
              <Tooltip title="キャンセル">
                {/* キャンセル */}
                <img
                  className="course_button_img"
                  src="/static/images/cards/btn_cancel.png"
                  alt="cancel"
                  height="40px"
                  width="auto"
                />
              </Tooltip>
            </Button>
          </Box>
        </Box>
      </Modal>
      <Paper sx={{ width: "100%" }}>
        {isRoot ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Box
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                marginRight: "15px",
              }}
              onClick={() => {
                openModel && openModel();
              }}
            >
              <AddIcon
                style={{
                  color: "white",
                  fontSize: "22px",
                  backgroundColor: "red",
                  padding: "5px",
                  fontWeight: "bold",
                  borderRadius: "50%",
                  margin: "0 5px",
                }}
              />
              <Typography style={{ fontSize: "16px", cursor: "pointer" }}>
                お知らせを追加
              </Typography>
            </Box>
          </Box>
        ) : (
          ""
        )}

        <TableContainer sx={{ height: 230 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <TableHead>
              <TableRow>
                {(isRoot ? headCellsRoot : headCells).map((headCell) => (
                  <StyledTableCell
                    key={headCell.key}
                    align={headCell.numeric}
                    style={{ paddingLeft: headCell.key === "id" ? "40px" : "" }}
                    // padding={"normal"}
                    sortDirection={orderBy === headCell.key ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.key}
                      direction={orderBy === headCell.key ? order : "asc"}
                      onClick={() => createSortHandler(headCell.key)}
                    >
                      {headCell.label}
                      {orderBy === headCell.key ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* render list Notification */}
              {listNotification &&
                listNotification?.map((data) => {
                  return (
                    <TableRow hover tabIndex={-1} key={data.id}>
                      <StyledTableCell
                        align="left"
                        style={{
                          backgroundColor:
                            isRoot && !data.is_public ? "#a9a9a9" : "",
                          paddingLeft: "40px",
                        }}
                        width={50}
                      >
                        <Typography>{data.id}</Typography>
                      </StyledTableCell>
                      {isRoot ? (
                        <StyledTableCell
                          align="left"
                          width={200}
                          style={{
                            backgroundColor:
                              isRoot && !data.is_public ? "#a9a9a9" : "",
                          }}
                        >
                          <Typography>{data.public_start}</Typography>
                        </StyledTableCell>
                      ) : (
                        ""
                      )}
                      {isRoot ? (
                        <StyledTableCell
                          align="left"
                          width={200}
                          style={{
                            backgroundColor:
                              isRoot && !data.is_public ? "#a9a9a9" : "",
                          }}
                        >
                          <Typography>{data.public_end}</Typography>
                        </StyledTableCell>
                      ) : (
                        ""
                      )}
                      <StyledTableCell
                        align="left"
                        style={{
                          backgroundColor:
                            isRoot && !data.is_public ? "#a9a9a9" : "",
                        }}
                      >
                        <Typography
                          style={{
                            display: "inline-block",
                            width: "550px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {data.title}
                        </Typography>
                      </StyledTableCell>
                      {isRoot ? (
                        <StyledTableCell
                          width={80}
                          align="center"
                          style={{
                            backgroundColor:
                              isRoot && !data.is_public ? "#a9a9a9" : "",
                          }}
                        >
                          <Tooltip title="編集">
                          <Button
                            onClick={() => hanldeUpdate(data)}
                            style={{ cursor: "pointer" }}
                          >
                              <img
                                className=""
                                src="/static/images/cards/pencil_icon.png"
                                alt="pencil_icon"
                                height="20px"
                                width="auto"
                              />
                          </Button>
                          </Tooltip>

                        </StyledTableCell>
                      ) : (
                        ""
                      )}
                      {isRoot ? (
                        <StyledTableCell
                          width={80}
                          align="center"
                          style={{
                            backgroundColor:
                              isRoot && !data.is_public ? "#a9a9a9" : "",
                          }}
                        >
                         <Tooltip title="削除">
                          <Button
                            onClick={() => hanldeClickButtonDelete(data.id)}
                            style={{ cursor: "pointer" }}
                          >
                              <img
                                className=""
                                src="/static/images/cards/delete_icon.png"
                                alt="delete_icon"
                                height="20px"
                                width="auto"
                              />
                          </Button>
                          </Tooltip>
                        </StyledTableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
