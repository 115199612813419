export function authHeader() {
    // return authorization header with jwt token
    //let access_token = JSON.parse(sessionStorage.getItem('access_token'));
    let access_token = JSON.parse(sessionStorage.getItem('access_token'));

    if (access_token) {
        //return { 'Authorization': 'Bearer ' + access_token};
        return access_token;
    } else {
        return {};
    }
}

export function getCurrentIdAccount() {
    // get current account id set in local storage and return id 
    let current_account_id = JSON.parse(sessionStorage.getItem('current_account_id'));
    return current_account_id
}

