import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { userService } from "../services/user.service";
import TextField from "@mui/material/TextField";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import Checkbox from "@mui/material/Checkbox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import jaLocale from "dayjs/locale/ja";
import { IconButton, Tooltip } from '@mui/material';

// function convert to media state
const convertToEditorState = (html) => {
  if (!html) return EditorState.createEmpty();
  const blocksFromHTML = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(blocksFromHTML);
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};

// convert Date to String
const convertDateToString = (date) => {
  if (!date) return "";
  return date.format("YYYY-MM-DD HH:mm:ss");
};

export default function NotificationModel(props) {
  const { open, onCancel, callList, detail } = props;
  const datePickerRef = useRef(null);

  const [errorText, setErrorText] = useState("");

  const [notificationDetail, setNotificationDetail] = useState({
    title: "",
    is_public: false,
    public_start: null,
    public_end: null,
    body: "",
  });
  const [validate, setValidate] = useState(false);
  const [diffStartDate, setDiffStartDate] = useState(0);
  const [diffDate, setDiffDate] = useState(0);
  const [invalidStartDate, setInvalidStartDate] = useState(true);
  const [invalidEndDate, setInvalidEndDate] = useState(true);

  useEffect(() => {
    setNotificationDetail({
      ...detail,
      public_start: detail?.public_start ? dayjs(detail.public_start) : null,
      public_end: detail?.public_end ? dayjs(detail.public_end) : null,
      body: convertToEditorState(detail?.body),
    });
    setErrorText("");
  }, [detail, open]);

  useEffect(() => {
    if (notificationDetail.public_start) {
      const public_start = convertDateToString(notificationDetail.public_start);
      const isValid = public_start != "Invalid Date";
      setInvalidStartDate(isValid);
    } else {
      setInvalidStartDate(true);
    }

    if (notificationDetail.public_end) {
      const public_end = convertDateToString(notificationDetail.public_end);
      const isValid = public_end != "Invalid Date";
      setInvalidEndDate(isValid);
    } else {
      setInvalidEndDate(true);
    }
  }, [notificationDetail.public_end, notificationDetail.public_start]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        // close model calendar
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerRef]);

  // change value detail
  const handleChangleValue = (key, value) => {
    setNotificationDetail({ ...notificationDetail, [key]: value });
  };

  // handle create Notification
  const createNotification = (
    title,
    is_public,
    public_start,
    public_end,
    body,
    del_flg
  ) => {
    userService
      .addNotificaion({
        title,
        is_public,
        public_start,
        public_end,
        body,
        del_flg,
      })
      .then((data) => {
        // call back get list account again
        handleCloseModel();
        callList && callList();
      })
      .catch((error) => {
        setErrorText(error);
      });
  };

  // handle update account
  const updateNotification = (
    id,
    title,
    is_public,
    public_start,
    public_end,
    body
  ) => {
    userService
      .updateNotificaion({
        news_id: id,
        title,
        is_public,
        public_start,
        public_end,
        body,
        is_deleted: 0,
      })
      .then((data) => {
        // call back get list account again
        handleCloseModel();
        callList && callList();
      })
      .catch((error) => {
        setErrorText(error);
      });
  };

  // submit code check create or update
  const handleSubmit = (event) => {
    // event.preventDefault();
    const {
      id,
      title,
      is_public: is_pub,
      public_start: p_start,
      public_end: p_end,
      body: bd,
    } = notificationDetail;

    if (!title || !p_start || !invalidEndDate || !invalidStartDate) {
      setValidate(true);
    } else {
      const body = draftToHtml(convertToRaw(bd.getCurrentContent()));
      const public_start = convertDateToString(p_start);
      const public_end = convertDateToString(p_end);
      const is_public = is_pub ? 1 : 0;
      if (id) {
        updateNotification(
          id,
          title,
          is_public,
          public_start,
          public_end,
          body,
          0
        );
      } else {
        createNotification(title, is_public, public_start, public_end, body, 0);
      }
    }
  };

  // clear local state and close model
  const handleCloseModel = () => {
    setNotificationDetail({
      title: "",
      is_public: false,
      public_start: null,
      public_end: null,
      body: "",
    });
    setValidate(false);
    setErrorText("");
    onCancel && onCancel();
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translate(-50%, -10%)",
          width: "45vw",
          bgcolor: "background.paper",
          padding: "20px 50px",
          outline: "none",
          borderRadius: "10px",
          maxHeight: "735px"
        }}
      >
        <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
          お知らせ
        </Typography>
        {errorText ? (
          <Typography
            style={{
              textAlign: "center",
              color: "red",
              marginTop: "10px",
              fontSize: "14px",
            }}
          >
            {errorText}
          </Typography>
        ) : (
          ""
        )}
        {/* form */}
        <Box className="content">
          <Box>
            <TextField
              id="title"
              style={{ width: "100%", marginTop: "10px" }}
              size="small"
              label="タイトル（最大64文字)"
              variant="standard"
              required
              name="title"
              inputProps={{ maxLength: 64 }}
              value={notificationDetail.title}
              error={validate && !notificationDetail.title ? true : false}
              InputLabelProps={{ shrink: notificationDetail.title? true:false  }}
              onChange={(event) =>
                handleChangleValue("title", event.target.value)
              }
            />
          </Box>

          {/* Public */}
          <Box style={{ width: "100%", marginTop: "10px" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
              onChange={() =>
                handleChangleValue("is_public", !notificationDetail.is_public)
              }
            >
              {notificationDetail.is_public ? (
                <Checkbox
                  style={{
                    marginLeft: "-10px",
                    "& .MuiSvgIconRoot": { fontSize: 28 },
                  }}
                  checked={true}
                />
              ) : (
                <Checkbox
                  style={{
                    marginLeft: "-10px",
                    "& .MuiSvgIconRoot": { fontSize: 28 },
                  }}
                  checked={false}
                />
              )}

              <Typography style={{ marginLeft: "0px", color: "gray" }}>
                掲載する
              </Typography>
            </Box>
         
          </Box>

          {/* start date */}
          <Box style={{ width: "100%", marginTop: "10px" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                position: "relative",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                locale={jaLocale}
              >
                <DateTimePicker
                  sx={{ width: "100%", border: "none" }}
                  slotProps={{
                    textField: {
                      required: true,
                      error:
                        (validate && !notificationDetail.public_start) ||
                        !invalidStartDate
                          ? true
                          : false,
                    },
                  }}
                  value={notificationDetail.public_start}
                  onChange={(value) =>
                    handleChangleValue("public_start", value)
                  }
                  label="掲載開始日（掲載日)"
                  format="YYYY-MM-DD HH:mm:ss"
                  views={[
                    "year",
                    "month",
                    "day",
                    "hours",
                    "minutes",
                    "seconds",
                  ]}
                 
                />
              </LocalizationProvider>
            </Box>
          </Box>

          {/* End date */}
          <Box style={{ width: "100%", marginTop: "20px" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                position: "relative",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                locale={jaLocale}
              >
                <DateTimePicker
                  sx={{ width: "100%", border: "none" }}
                  value={notificationDetail.public_end}
                  localeText={"ja-JP"}
                  slotProps={{
                    textField: {
                      required: false,
                      error: !invalidEndDate,
                    },
                  }}
                  onChange={(value) => handleChangleValue("public_end", value)}
                  label="掲載終了日（なしの場合は、無期限掲載となります)"
                  format="YYYY-MM-DD HH:mm:ss"
                  views={[
                    "year",
                    "month",
                    "day",
                    "hours",
                    "minutes",
                    "seconds",
                  ]}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          {/* Content */}
          <Box style={{ marginTop: "10px" }}>
            <Typography> 本文 </Typography>
            <Box
              style={{
                border: "1px solid gray",
              }}
            >
              <Editor
                editorState={notificationDetail.body}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                toolbarClassName="demo-toolbar"
                editorStyle={{ height: '230px' }}
                onEditorStateChange={(value) =>
                  handleChangleValue("body", value)
                }
              />
            </Box>
          </Box>
        </Box>
        {/* Footer */}
        <Box
          className="footer"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Button  onClick={handleSubmit} style={{padding:"0px" }}>
            {/* 登録 */}
            <Tooltip title="登録する">
                <img className="" src="/static/images/cards/btn_register.png" alt="btn_register" height="40px" width="auto"/>
            </Tooltip>
          </Button>
          <Button
            style={{ marginLeft: "20px", padding:"0px" }}
            onClick={handleCloseModel}
          >
            {/* 閉じる */}
            <Tooltip title="閉じる">
              <img className="" src="/static/images/cards/btn_close.png" alt="btn_close" height="40px" width="auto"/>
            </Tooltip>

          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
