import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Modal from "@mui/material/Modal";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Header from './Header';
import SideList from './SideList';
import { userService } from '../services/user.service';
import ConfirmDialog from './ConfirmDialog';
import { commonConstants } from '../constants/common.constants';
import { getCurrentIdAccount } from '../helpers/auth-header';
import SelfParticipant from './SelfParticipant';
import AddIcon from "@mui/icons-material/Add";
import CourseUploadModel from './CourseUploadModel';
import CourseInputFileModel from './CourseInputFileModel';
import SelfNotification from './SelfNotification';
// import CourseInputFileModel from './CourseInputfileModel';

// get current account id after login
const currentAccountId = getCurrentIdAccount()


const StyledTableCell = styled(TableCell)({
  padding: '5px 10px',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#98b5d1',
  },
});

const StyledSelect = styled(Select)({
  fontSize: '0.7rem',
  letterSpacing: '-0.05em',
  fontVariantLigatures: 'none',
});

const StyledMenuItem = styled(MenuItem)({
  fontSize: '0.7rem',
  letterSpacing: '-0.05em',
  fontVariantLigatures: 'none',
  padding: '5px 10px',
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

var errorMsg = '';

//動画・コーステーブル start
function createCourseData(contents_name, thumbnail, contents_time, usage, watching, download, streaming, del_flg,  contents_id, is_deleted) {
  return {
    contents_name,
    thumbnail,
    contents_time,
    usage,
    watching,
    download,
    streaming,
    del_flg,
    contents_id,
    is_deleted
  };
}

function usageList(id_account, id_content, usage_content, dl_flg) {
  return {
    id_account,
    id_content,
    usage_content,
    dl_flg,
  };
}

function CourseTableHead(props) {
  const headCells = [
    {
      id: 'contents_name',
      numeric: false,
      disablePadding: true,
      label: 'コース名',
    },
    {
      id: 'thumbnail',
      numeric: true,
      disablePadding: false,
      label: 'サムネイル',
    },
    {
      id: 'play_time',
      numeric: true,
      disablePadding: false,
      label: '再生時間',
    },
    {
      id: 'usage',
      numeric: true,
      disablePadding: false,
      label: '利用状況',
    },
    // {
    //   id: 'watching',
    //   numeric: true,
    //   disablePadding: false,
    //   label: '視聴',
    // },
    /*{
      id: 'download',
      numeric: true,
      disablePadding: false,
      label: 'ダウンロード',
    },
    {
      id: 'streaming',
      numeric: true,
      disablePadding: false,
      label: 'ストリーミング',
    },*/
  ];
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'nomal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CourseTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

//const CourseTableToolbar = (props) => {
//  const { numSelected, selected, setCourseTable, setSelected, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg } = props;

//  return (
//    <Toolbar
//      sx={{
//       pl: { sm: 2 },
//        pr: { xs: 1, sm: 1 },
//        ...(numSelected > 0 && {
//          bgcolor: (theme) =>
//            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
//        }),
//      }}
//    >
//      {numSelected > 0 ? (
//        <Typography
//          sx={{ flex: '1 1 100%' }}
//          color="inherit"
//          variant="subtitle1"
//          component="div"
//        >
//          {numSelected} selected
//       </Typography>
//      ) : (
//        <Typography
//          sx={{ flex: '1 1 100%' }}
//          variant="h6"
//          id="tableTitle"
//          component="div"
//        >
//          {/* コース情報　全{courseLength}件 */}
//       </Typography>
//      )}
//
//      {/* {numSelected > 0 ? ( */}
//      <Tooltip title="確定(予定)">
//        <img src="/static/images/cards/decision.png" alt="Add" height="30" onClick={(event) => decisionClick(event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg)} />
//      </Tooltip>
//      {/*<Tooltip title="Delete">
//       <Button type="submit"><img src="/static/images/cards/delete.png" alt="delete" height="30" onClick={(event) => deleteclick(event, selected, setCourseTable, setSelected)} />
//        </Button>
//      </Tooltip>
//       ) : (
//        <img src="/static/images/cards/delete_disable.png" alt="delete" />
//      )} */}
//    </Toolbar>
//  );
//};


function decisionClick(event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg, setComfirmUpdate, setUsageChangeList){

  console.log({
    usageChangeList: usageChangeList,
  });

  let id_list = []
  let usage_list = []

  for (let i = 0; i < usageChangeList.length; i++) {
      id_list.push(usageChangeList[i].id_content)
      usage_list.push(usageChangeList[i].usage_content)
  }

  console.log({id_list, usage_list});

  userService.update_content_use(id_list, usage_list)
  .then(
    data => {

      let usage = []
      for (let i = 0; i < data.result.items.length; i++) {
        var hogehoge = usageList(data.result.items[i].id_account, data.result.items[i].id_content, data.result.items[i].usage_content, data.result.items[i].dl_flg)
        usage[i] = hogehoge
      }

      setUserContents(usage);

      console.log({
        mogemoge: usage,
     });
     setComfirmUpdate(false)
     setErrorFlg(true)
     setConDialog(true)
     
    },
    error => {
      errorMsg = error;
      setComfirmUpdate(false)
      setErrorFlg(false)
      setErrorMsg(error)
      setConDialog(true)

      console.log({
         errorMsg: errorMsg,
      });
    }
  );
  setConDialog(false)
}

/*CourseTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
  setCourseTable: PropTypes.any.isRequired,
  setSelected: PropTypes.any.isRequired,
};*/


function CourseTable(props) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('contents_id');
  const [openInputFile, setOPenInputFile]  =React.useState(false);
  const [comfirmUpdate, setComfirmUpdate] = React.useState(false);
  //const [selected, setSelected] = React.useState([]);

  
  const courses = props.table;
  const userContents = props.userContents;
  const setUserContents = props.setUserContents

  const usageChangeList = props.usageChangeList
  const setUsageChangeList = props.setUsageChangeList

  const setCourseTable = props.setCourseTable;
  const setSelectedCourse = props.setSelectedCourse;
  const selectedCourse = props.selectedCourse;

  const setConDialog = props.setConDialog;
  const setErrorFlg = props.setErrorFlg;
  const setErrorMsg = props.setErrorMsg;
  const getContentList = props.getContentList;
  const settingContent = props.settingContent;

  const handleCloseInputFile =()=>{
    setOPenInputFile(false)
  }

  const handleOpenInputFile= ()=>{
    setOPenInputFile(true)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const checkDataBeforeUpdate =(event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg)=>{
    const index = usageChangeList.findIndex((item)=> item.usage_content ===9)
    if (index>=0){
      setComfirmUpdate(true)
    }
    else{
      setComfirmUpdate(false)
      decisionClick(event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg, setComfirmUpdate, setUsageChangeList)
    }
  }

  const handleDelete = (event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg)=>{
    decisionClick(event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg, setComfirmUpdate, setUsageChangeList)
  }

  const onCloseConfirmDelete =()=>{
    setComfirmUpdate(false)
  }

  return (
    <Box sx={{ width: '100%' }}>

      {/* Model comfirm delete */}
      <Modal
        open={comfirmUpdate}
        onClose={onCloseConfirmDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            width: 400,
            bgcolor: "background.paper",
            border: "1px solid gray",
            borderRadius: "10px",
            outline: "none",
            p: 1,
          }}
        >
          <Typography
            id="modal-modal-title"
            fontWeight="bold"
            textAlign="center"
            variant="h7"
            component="h4"
            style={{ marginTop: "10px" }}
          >
            削除するコースがあります。
          </Typography>
          <Typography
            id="modal-modal-description"
            textAlign="center"
            variant="h7"
            component="h4"
          >
            よろしいですか？
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "70%",
              margin: "50px auto 10px",
            }}
          >
            <Button
             style={{padding:"0px",}}
            onClick={(event)=>handleDelete(event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg)} 
            >
              {/* はい */}
              <Tooltip title="削除する">
                 <img className="course_button_img" src="/static/images/cards/btn_confirm_delete_course.png" alt="cancel" height="40px"  width="auto"/>
              </Tooltip>

            </Button>
            <Button
              style={{padding:"0px",}}
              onClick={onCloseConfirmDelete}
            >
              <Tooltip title="キャンセル">
               <img className="course_button_img" src="/static/images/cards/btn_cancel.png" alt="cancel" height="40px" width="auto"/>
              </Tooltip>
            </Button>
          </Box>
        </Box>
      </Modal>
      <Grid container spacing={0} sx={{ height: 31 }}>
      <Grid item xs={5} >
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            動画・コース 
          </Typography>
        </Grid>
        <Grid item xs={6}>
           <Box
            style={{
              display: "flex",
              alignItems:"center",
              justifyContent:"flex-end",        
              marginRight:"30px"      
            }}
          >
            <Button sx={{ p: 0, mb: 1 }}>
              <Tooltip title="コースを追加する">
              <img className="" src="/static/images/cards/btn_model_create_course.png" alt="btn_model_create_course" height="30px" width="auto" onClick={handleOpenInputFile}/>
              </Tooltip>
              </Button>
          </Box>
        </Grid>

        <Grid item xs={1}>
          <div align="right">
            <Button type="submit" sx={{ p: 0, mb: 1 }}>
              <Tooltip title="決定">
                <img src="/static/images/cards/decision.png" alt="Add" height="30" onClick={(event) => checkDataBeforeUpdate(event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg)} />
              </Tooltip>
            </Button>
          </div>
        </Grid>
      </Grid>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ height: 320 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <CourseTableHead
              numSelected={selectedCourse.length}
              order={order}
              orderBy={orderBy}
              //onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              // rowCount={courses.size}
            />
            <TableBody>
              {stableSort(courses, getComparator(order, orderBy))
                .map((course, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={course.contents_id}
                    >
                      <StyledTableCell
                        component="th"
                        scope="row"
                        padding="none"
                        bgcolor="transparent"
                        style={course.usage === 0 ? { background: "transparent" } : { background: "#ff9e3d" }}
                      >
                        {course.contents_name}
                      </StyledTableCell>
                      <StyledTableCell align="right"><img src={commonConstants.CLOUDFRONT_URL + course.thumbnail} alt="CourseImage" height="40" width="150"/></StyledTableCell>
                      <StyledTableCell align="right">{course.contents_time}</StyledTableCell>
                      <StyledTableCell align="right">
                      <StyledSelect
                        //value={["1", "2", "3"]}
                        size="small"
                        defaultValue={course.usage}
                        sx={{ minWidth: '130px' }}
                        onChange={(e) => usageChange(e, course.usage, course.contents_id, usageChangeList, setUsageChangeList)}
                      >
                        <StyledMenuItem value={0}>利用しない</StyledMenuItem>
                        <StyledMenuItem value={1}>ダウンロード</StyledMenuItem>
                        <StyledMenuItem value={2}>ストリーミング</StyledMenuItem>
                        {course.is_deleted?<StyledMenuItem value={9} >削除する</StyledMenuItem>:""}

                      </StyledSelect>
                      </StyledTableCell>
                      {/* <StyledTableCell align="right">
                        {course.watching ? <a href={commonConstants.CLOUDFRONT_URL + course.watching}>視聴</a>:"視聴"}
                      </StyledTableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* <CourseUploadModel/> */}
      <CourseInputFileModel open={openInputFile} onClose={handleCloseInputFile} getContentList={getContentList} settingContent={settingContent}/>
    </Box>
  );
}
//動画・コーステーブル end

function usageChange(e, usage, content_id, usageChangeList, setUsageChangeList){
  let usageChange = []
  for (let i = 0; i < usageChangeList.length; i++) {
    if (content_id === usageChangeList[i].id_content){
      var hogehoge = usageList(usageChangeList[i].id_account, usageChangeList[i].id_content, e.target.value, usageChangeList[i].dl_flg)
    }
    else{
      var hogehoge = usageList(usageChangeList[i].id_account, usageChangeList[i].id_content, usageChangeList[i].usage_content, usageChangeList[i].dl_flg)
    }
    
    usageChange[i] = hogehoge
  }
  setUsageChangeList(usageChange);
}
/******************************************************************************************************************************************************************************/


function createHistoryData(date, startTime, steps, entryNumber, goalCriteria, content_name, movementTime, log_id) {
  return {
    date,
    startTime,
    steps,
    entryNumber,
    goalCriteria,
    content_name,
    movementTime,
    log_id,
  };
}

function HistoryTableHead(props) {
  const headCells = [
    {
      id: 'date',
      numeric: false,
      disablePadding: true,
      label: '日付',
    },
    {
      id: 'startTime',
      numeric: true,
      disablePadding: false,
      label: '開始時刻',
    },
    {
      id: 'steps',
      numeric: true,
      disablePadding: false,
      label: '歩数',
    },
    {
      id: 'entryNumber',
      numeric: true,
      disablePadding: false,
      label: '参加人数',
    },
    {
      id: 'goalCriteria',
      numeric: true,
      disablePadding: false,
      label: 'ゴール条件',
    },
    {
      id: 'content_name',
      numeric: true,
      disablePadding: false,
      label: 'コース名',
    },
    {
      id: 'movementTime',
      numeric: true,
      disablePadding: false,
      label: '運動時間',
    },
  ];

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all historys',
            }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

//const HisyoryTableToolbar = (props) => {
//  const { numSelected } = props;
//  return (
//    <Toolbar
//      sx={{
//        pl: { sm: 2 },
//        pr: { xs: 1, sm: 1 },
//        ...(numSelected > 0 && {
//          bgcolor: (theme) =>
//            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
//        }),
//      }}
//    >
//      {numSelected > 0 ? (
//        <Typography
//          sx={{ flex: '1 1 100%' }}
//          color="inherit"
//          variant="subtitle1"
//         component="div"
//        >
//          {numSelected} selected
//        </Typography>
//      ) : (
//        <Typography
//          sx={{ flex: '1 1 100%' }}
//          variant="h6"
//          id="tableTitle"
//          component="div"
//        >
//          利用履歴
//        </Typography>
//      )}
//      {/*<Tooltip title="Delete">
//        <img src="/static/images/cards/delete.png" alt="delete" height="30" />
//      </Tooltip>*/}
//    </Toolbar>
//  );
//};

function HistoryTable(props) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('log_id');

  const historys = props.table
  const selectedHistory = props.selectedHistory
  const setSelectedHistory = props.setSelectedHistory

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = historys.map((n) => n.log_id);
      setSelectedHistory(newSelecteds);
      return;
    }
    setSelectedHistory([]);
  };

  const handleClick = (event, log_id) => {
    const selectedIndex = selectedHistory.indexOf(log_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedHistory, log_id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedHistory.slice(1));
    } else if (selectedIndex === selectedHistory.length - 1) {
      newSelected = newSelected.concat(selectedHistory.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedHistory.slice(0, selectedIndex),
        selectedHistory.slice(selectedIndex + 1),
      );
    }
    setSelectedHistory(newSelected);
  };

  const isSelected = (log_id) => selectedHistory.indexOf(log_id) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={0} sx={{ height: 31, mt: 1 }}>
        <Grid item xs={6}>
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            利用履歴
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <div align="right">
            {selectedHistory.length > 0 ? (
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selectedHistory.length} selected
              </Typography>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={2}>
          <div align="right">
            <Button type="submit" sx={{ p: 0, mb: 1 }}>
              <Tooltip title="ファイル出力">
                <img src="/static/images/cards/file_output.png" alt="File Output" height="30" onClick={(event) => outputCsvClick(event, selectedHistory, historys)} />
              </Tooltip>
            </Button>
          </div>
        </Grid>
      </Grid>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ height: 260 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <HistoryTableHead
              numSelected={selectedHistory.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={historys.length}
            />
            <TableBody>
              {stableSort(historys, getComparator(order, orderBy))
                .map((history, index) => {
                  const isItemSelected = isSelected(history.log_id);
                  const labelId = `history-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={history.log_id}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, history.log_id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {history.date}
                      </StyledTableCell>
                      <StyledTableCell align="right">{history.startTime}</StyledTableCell>
                      <StyledTableCell align="right">{history.steps}</StyledTableCell>
                      <StyledTableCell align="right">{history.entryNumber}</StyledTableCell>
                      <StyledTableCell align="right">{history.goalCriteria}</StyledTableCell>
                      <StyledTableCell align="right">{history.content_name}</StyledTableCell>
                      <StyledTableCell align="right">{history.movementTime}</StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
//利用履歴テーブル end

function AdditionInformation(props) {

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={0} sx={{ height: 31, mt: 1 }}>
        <Grid item xs={6}>
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            退会方法
          </Typography>
        </Grid>
      </Grid>
      <Paper sx={{ width: '100%' ,padding: '1rem'}}>
      <List>
        <ListItem>
            <ListItemText>
              <Typography>
                ■ユーザアカウントおよびサーバ保持データの管理について
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                1. ユーザアカウントおよび、弊社サーバで保持しているデータの削除を希望する場合は、下記宛先へメールでご連絡ください。
              </Typography>
              <br />
              <Typography sx={{ marginLeft: '60px' }}>
                アルプスシステムインテグレーション株式会社 「屋内疑似お散歩レク」担当
              </Typography>
              <Typography sx={{ marginLeft: '60px' }}>
                <a href="mailto:fun@alsi.co.jp">fun@alsi.co.jp</a>
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                2. 弊社のサーバで保持しているデータは、以下になります。
              </Typography>
              <br />
              <Typography sx={{ marginLeft: '60px' }}>
                保有データ: 組織名、担当者名、電話番号、メールアドレス、ニックネーム、オリジナルコース用の動画
              </Typography>
              <Typography sx={{ marginLeft: '150px' }}>
                利用履歴(レクレーション日付、開始時刻、歩数、参加人数、ゴール条件、コース名、運動時間)
              </Typography>
              <br />
              <Typography sx={{ marginLeft: '60px' }}>
                保有期間{' '}
                <span style={{ padding: '0 5px' }}> </span>
                :削除依頼受領まで
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                3. ユーザアカウントおよび利用履歴の保持期間は、以下になります。
              </Typography>
              <br />
              <Typography sx={{ marginLeft: '60px' }}>
                削除依頼受領日の翌営業日から三日以内
              </Typography>
              <Typography sx={{ float: 'right'}}>
                以上
              </Typography>
              
            </ListItemText>
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
}
//利用履歴テーブル end


function outputCsvClick(event, selectedHistory, historys){
  //文字列型で二次元配列のデータ

 let csvData = []

 for (let i = 0; i < selectedHistory.length; i++){
  for (let r = 0; r < historys.length; r++){
    if (selectedHistory[i] === historys[r].log_id){

      console.log({
        check1: selectedHistory[i],
        check2: historys[r].log_id,
      });

      let data = []
      data.push(historys[r].date);
      data.push(historys[r].startTime);
      data.push(historys[r].steps);
      data.push(historys[r].entryNumber);
      data.push(historys[r].goalCriteria);
      data.push(historys[r].content_name);
      data.push(historys[r].movementTime);

      csvData.push(data);

      console.log({
        check3: "in",
      });
    }
  }
 }

  console.log({
    csvData: csvData,
 });

 if (csvData.length === 0){

 }

  //作った二次元配列をCSV文字列に直す。
  let csv_string  = ""; 
  for (let d of csvData) {
      csv_string += d.join(",");
      csv_string += '\r\n';
  }   

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const date = currentDate.getDate();

  const hour = formatTime(currentDate.getHours());
  const minute = formatTime(currentDate.getMinutes());
  const second = formatTime(currentDate.getSeconds());

  //ファイル名の指定
  let file_name   = year + "-" + month + "-" + date + "-" + hour + "-" + minute + "-" + second + "-Log.csv";
  var bom = new Uint8Array([0xEf, 0xBB, 0xBF]);

  //CSVのバイナリデータを作る
  let blob        = new Blob([bom, csv_string], {type: "text/csv"});
  let uri         = URL.createObjectURL(blob);

  //リンクタグを作る
  let link        = document.createElement("a");
  link.download   = file_name;
  link.href       = uri;

  //作ったリンクタグをクリックさせる
  document.body.appendChild(link);
  link.click();

  //クリックしたら即リンクタグを消す
  document.body.removeChild(link);
  link = null;
}

function formatTime(val) {
  return ("0" + val).slice(-2);
}


/******************************************************************************************************************************************************************************/

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100vh',
});

const MainContainer = styled(Box)({
  margin: '8px 20px',
});


export default function UserManagement(props) {
  document.title = '屋内疑似お散歩レク';
  const [courseTable, setCourseTable] = React.useState([]);
  //const [currentUserId, setCurrentUserId] = React.useState(null);
  const [userContents, setUserContents] = React.useState([]);

  const [usageChangeList, setUsageChangeList] = React.useState([]);

  const [historyTable, setHistoryTable] = React.useState([]);

  const [selectedCourse, setSelectedCourse] = React.useState([]);
  const [selectedHistory, setSelectedHistory] = React.useState([]);

  const [ConDialog, setConDialog] = React.useState(false);

  const [errorFlg, setErrorFlg] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);

  // get content list
  const getContentList =()=>{
    userService.getContentList()
    .then(
      data => {
        let courseList = []

        for (let i = 0; i < data.result.items.length; i++) {
          var usage = "";
          if (userContents !== null) {
            userContents.forEach((userContent) => {
              if(userContent.id_content == data.result.items[i].contents_id) {   
                switch(userContent.usage_content) {
                  case 1:
                    //usage = "ダウンロード";
                    usage = 1;
                    break;
                  case 2:
                    //usage = "ストリーミング";
                    usage = 2;
                    break;
                  default:
                    //usage = "未使用";
                    usage = 0;
                }
              }
            });
            if(usage == "") {
              usage = 0;          
            }
          } else {
            usage = 0;          
          }
          if (data.result.items[i].del_flg === false) {
            var hogehoge = createCourseData(data.result.items[i].contents_name, data.result.items[i].contents_image, data.result.items[i].contents_time, usage,
              data.result.items[i].short_movie_url, data.result.items[i].download_url,data.result.items[i].streaming_url, data.result.items[i].del_flg, data.result.items[i].contents_id,  data.result.items[i].delete)
            // courseList[i] = hogehoge;
            courseList.push(hogehoge);
          }
        }
        setCourseTable(courseList)
      },
      error => {
         //errorMsg = error;
        console.log({
           //errorMsg: errorMsg,
        });
      }
    );
  }

  // 画面の初期表示時に呼び出し
  useEffect(() => {
    if (userContents.length) {
      getContentList()
    }
  },[userContents]);

  const settingContent =()=>{
    userService.settingContent()
      .then(
        data => {
          let usage = []
          for (let i = 0; i < data.result.items.length; i++) {
            var hogehoge = usageList(data.result.items[i].id_account, data.result.items[i].id_content, data.result.items[i].usage_content, data.result.items[i].dl_flg)
            usage[i] = hogehoge
          }
          setUserContents(usage);
          setUsageChangeList(usage);

          console.log({
            mogemoge: usage,
         });
        },
        error => {
           errorMsg = error;
          console.log({
             errorMsg: errorMsg,
          });
        }
      );
  }

    // 左のツリーでユーザーを選択した際に呼び出し
    useEffect(() => {
      settingContent()
  },[]);

  // ユーザーのコンテンツ利用状況が取得出来たら呼び出し
  // useEffect(() => {
  //   createCourse(courseTable, userContents, setCourseTable)
  // },[userContents]);


  useEffect(() => {
    userService.getLogList()
      .then(
        //returnされたdataの確認用ログ
        data => {
          console.log({
            deta: data,
          });

          let historyList = []
          for (let i = 0; i < data.result.items.length; i++) {

            var hogehoge = createHistoryData(data.result.items[i].date, data.result.items[i].start_time, data.result.items[i].steps,
              data.result.items[i].member, data.result.items[i].goal_condition, data.result.items[i].course, data.result.items[i].exercise_time, data.result.items[i].log_id)
            historyList[i] = hogehoge
          }

          setHistoryTable(historyList)
        },
        error => {
          errorMsg = error;
          console.log({
            errorMsg: errorMsg,
          });
          //ログイン失敗時の処理は、現状特になし
          // loginFailure(error);
        }
      );
    //return;
  }, [])

  return (
    <Wrapper>
      <CssBaseline />
      <Header />
      <MainContainer>
        <ConfirmDialog ConDialog={ConDialog} errorFlg={errorFlg} errorMsg={errorMsg} ></ConfirmDialog>
        {/* <SelfNotification currentUserId={currentAccountId}/> */}
        {/* <CourseTable table={courseTable} userContents={userContents} setCourseTable={setCourseTable} setUserContents={setUserContents} selectedCourse={selectedCourse} setSelectedCourse={setSelectedCourse} /> */}
        <CourseTable table={courseTable} userContents={userContents} setCourseTable={setCourseTable} setUserContents={setUserContents} selectedCourse={selectedCourse} setSelectedCourse={setSelectedCourse} usageChangeList={usageChangeList} setUsageChangeList={setUsageChangeList} setConDialog={setConDialog} setErrorFlg={setErrorFlg} setErrorMsg={setErrorMsg} getContentList={getContentList} settingContent={settingContent}/>
        <SelfParticipant currentUserId={currentAccountId} setConDialog={setConDialog} setErrorFlg={setErrorFlg} setErrorMsg={setErrorMsg}/>
        <HistoryTable table={historyTable} selectedHistory={selectedHistory} setSelectedHistory={setSelectedHistory} />
        <AdditionInformation />
      </MainContainer>
    </Wrapper>
  );
}
