import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddAccountModel from "./AddAccountModel";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { userService } from "../services/user.service";
import { Tooltip } from "@mui/material";

export default function InfoUserAccount(props) {
  const { currentUserId, listAccount = [], getAccountList , setCurrentUserId} = props;
  const [detail, setDetail] = useState({});
  const [open, setOpen] = useState(false);
  const [openModel, setOpenModelComfirm]= useState(false)

  useEffect(() => {
    // get current account
    if (currentUserId) {
      const index = listAccount.findIndex(
        (item) => item.account_id === currentUserId
      );
      index >= 0 && setDetail(listAccount[index]);
    }
    // or get root account
  }, [currentUserId, listAccount]);

  // open model update
  const handleOpen = () => {
    setOpen(true);
  };
  //   close model update
  const handleClose = () => {
    setOpen(false);
  };

  const clearDetail = () => {
    setDetail({});
  };

  // open model update
  const handleOpenConfirmDelete = () => {
    setOpenModelComfirm(true);
  };
  //   close model update
  const handleCloseConfirmDelete = () => {
    setOpenModelComfirm(false);
  };

  const handleDelete =()=>{
    if (detail.account_id){

      const index = listAccount.findIndex(item=> item.account_id===detail.account_id)
      let currentUserId = null
      if ((index+1) <listAccount.length &&index >-1){
        currentUserId= listAccount[index+1].account_id
      }
      
        userService
        .deleteUser(detail.login_id)
        .then((data) => {
          // call back get list account again
          handleClose()
          handleCloseConfirmDelete()
          clearDetail && clearDetail()
          getAccountList && getAccountList();
          setCurrentUserId(currentUserId)
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  return (
    <Box sx={{ padding: "0px 10px 10px", minHeight: "100px" }}>
      {currentUserId?<Grid container columnSpacing={5} rowSpacing={1}>
        <Grid item xs={2} lg={2} sm={12} md={6}>
          <Box>
            <Typography>ID</Typography>
            <Typography fontSize="16px" marginTop={"10px"} style={{wordWrap:"break-word"}}>
              {detail?.login_id}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} lg={2} sm={12} md={6}>
          <Box>
            <Typography>担当者</Typography>
            <Typography fontSize="16px" marginTop={"10px"} style={{wordWrap:"break-word"}}>
              {detail?.person}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} lg={2} sm={12} md={6}>
          <Box>
            <Typography>電話番号</Typography>
            <Typography fontSize="16px" marginTop={"10px"} style={{wordWrap:"break-word"}}>
              {detail?.tel}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} lg={2} sm={12} md={6}>
          <Box>
            <Typography>セッション期間</Typography>
            <Typography fontSize="16px" marginTop={"10px"} style={{wordWrap:"break-word"}}>
              {detail?.token_expired_days}（日）
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3} lg={3} sm={12} md={6}>
          <Box>
            <Typography>MEMO</Typography>
            <Typography fontSize="16px" marginTop={"10px"} style={{wordWrap:"break-word"}}>
              {detail?.memo}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1} lg={1} sm={12} md={6}>
          {/* {detail?.account_id ? ( */}
            <Box>
            <Tooltip title="修正">
              <Button
                onClick={handleOpen}
                variant="contained"
                sx={{ width: "100%", borderRadius: "none" }}
              >
                    修正
              </Button>
              </Tooltip>
            </Box>
          {/* ) : (
            ""
          )} */}
        </Grid>
      </Grid>:""}
      <AddAccountModel
        open={open}
        onCancel={handleClose}
        clearDetail={clearDetail}
        detail={detail}
        getAccountList={getAccountList}
        handleOpenConfirmDelete={handleOpenConfirmDelete}
      />
        <Modal 
          open={openModel}
          onClose={handleCloseConfirmDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            width: 450,
            bgcolor: "background.paper",
            border: "1px solid gray",
            borderRadius: "10px",
            outline: "none",
            p: 1,
          }}
        >
          <Typography
            id="modal-modal-title"
            fontWeight="bold"
            textAlign="center"
            variant="h7"
            component="h4"
            style={{ marginTop: "10px" }}
          >
            ユーザをリストから削除します。
          </Typography>
          <Typography
            id="modal-modal-title"
            fontWeight="bold"
            textAlign="center"
            variant="h7"
            component="h4"
          >
            削除後、24時間以内にアプリの利用が停止されます。
          </Typography>
          <Typography
            id="modal-modal-description"
            textAlign="center"
            variant="h7"
            component="h4"
          >
            よろしいですか？
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "70%",
              margin: "50px auto 10px",
            }}
          >
            <Button style={{  padding:"0px" }}
            onClick={handleDelete}
            >
              <Tooltip title="削除する">
                 <img className="course_button_img" src="/static/images/cards/btn_confirm_delete_course.png" alt="ok" height="40px" width="auto"/>
              </Tooltip>
            </Button>
            <Button
              style={{  padding:"0px" }}
              onClick={handleCloseConfirmDelete}
            >
              <Tooltip title="キャンセル">
                <img className="course_button_img" src="/static/images/cards/btn_cancel.png" alt="cancel" height="40px" width="auto"/>
              </Tooltip>

            </Button>
          </Box>
        </Box>
        </Modal>
    </Box>
  );
}
