import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Backdrop,
  Typography,
  Box,
  TextField,
  duration,
  Tooltip,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { moveS3File, uploadVideo } from "../services/s3.service.js";
import { styled } from "@mui/material/styles";
import { commonConstants } from "../constants/common.constants";
import { userService } from "../services/user.service";
import { checkS3FileExists } from "../services/s3.service.js";
import { v4 as uuidv4 } from "uuid";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CircularProgress from "@mui/material/CircularProgress";
import "./style/course.css";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

// function move file to orther file s3
function handleMoveFile(currentPath, toPath) {
  moveS3File(currentPath, toPath, (data) => {});
}

export default function CourseUploadModel(props) {
  const {
    video,
    open,
    onClose,
    clearVideo,
    duratioVideo,
    getContentList,
    settingContent,
  } = props;

  const [progress, setProgress] = useState(0);
  const [urlThumbnail, setUrlThumbnail] = useState("");
  const [nameFile, setNameFile] = useState("");
  const [isTransfer, setIsTranfer] = useState(true);
  const [errorText, setErrorText] = useState("");
  const [clickSubmit, setClickSubmit] = useState(0);
  const [isUploadedStep, setIsUploadedStep] = useState(1);
  const [isConvertSuccess, setIsConvertSuccess] = useState(true);
  const [countConvert, setCountConvert] = useState(0);
  const [loadingUploadCourses , setLoadingUploadCourses] = useState(false);

  const uploadCancelledRef = useRef(true);
  const intervalIdRef = useRef(null);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    if (video) {
      uploadCancelledRef.current = true;
      setIsUploadedStep(1);
      // create uuid name
      const nameFile = uuidv4();
      setNameFile(nameFile);
      uploadVideo(
        video,
        `${commonConstants.S3_MEDIA_ORIGINAL_URI}${nameFile}.mp4`,
        (prog, cancelUpload) => {
          // get prgress upload from s3
          if (uploadCancelledRef.current) {
            setProgress(prog);
          } else {
            cancelUpload();
          }
        },
        (url) => {
          console.log("url", url);
          setProgress(0);
          setIsUploadedStep(2);
          clearVideo && clearVideo();
          intervalIdRef.current = setInterval(() => {
            checkConvertMedia(nameFile, intervalIdRef.current);
          }, 5000);

          // Stop calling check after 1 minute (60,000 milliseconds)
          timeoutIdRef.current = setTimeout(() => {
            clearInterval(intervalIdRef.current);
          }, 180000);

          return () => clearTimeout(timeoutIdRef.current);
        }
      );
    }
  }, [video]);

  // close and clear video clear validation
  const handleClose = () => {
    setErrorText("");
    setIsUploadedStep(1);
    setCountConvert(0);
    setIsConvertSuccess(true);
    setUrlThumbnail("");
    onClose && onClose();
    clearVideo && clearVideo();

    uploadCancelledRef.current = false;
    clearInterval(intervalIdRef.current);
    clearTimeout(timeoutIdRef.current);
    setProgress(0);
  };

  // move all file mp3, mp4, png to orther folder(media convert)
  const changleMultiFile = async (Originalname, contentId) => {
    // from path
    const mediaVideoUrl = `${commonConstants.S3_MEDIA_CONVERT_URI}${Originalname}.mp4`;
    let mediaThumbnailUrl = `${commonConstants.S3_MEDIA_CONVERT_URI}${Originalname}.0000000.png`;
    const mediaMusicUrl = `${commonConstants.S3_MEDIA_CONVERT_URI}${Originalname}.mp3`;

    // to path
    const videoUrl = `${commonConstants.S3_VIDEO_URI}video_${contentId}.mp4`;
    const thumbnailUrl = `${commonConstants.S3_THUMBNAIL_URI}thumbnail_${contentId}.png`;
    const musicUrl = `${commonConstants.S3_MUSIC_URI}music_${contentId}.mp3`;

    const isExistThumbnail5s = await checkS3FileExists(
      `${commonConstants.S3_MEDIA_CONVERT_URI}${Originalname}.0000001.png`
    );
    if (isExistThumbnail5s) {
      mediaThumbnailUrl = `${commonConstants.S3_MEDIA_CONVERT_URI}${Originalname}.0000001.png`;
    }

    // move
    handleMoveFile(mediaVideoUrl, videoUrl);
    handleMoveFile(mediaThumbnailUrl, thumbnailUrl);
    handleMoveFile(mediaMusicUrl, musicUrl);
    return { videoUrl, thumbnailUrl, musicUrl };
  };

  // check existing file was tranfered
  const checkConvertMedia = async (nameFile, interval) => {
    const existsVideo = await checkS3FileExists(
      `${commonConstants.S3_MEDIA_CONVERT_URI}${nameFile}.mp4`
    );
    const existsThumbnail = await checkS3FileExists(
      `${commonConstants.S3_MEDIA_CONVERT_URI}${nameFile}.0000000.png`
    );
    const existsThumbnail5s = await checkS3FileExists(
      `${commonConstants.S3_MEDIA_CONVERT_URI}${nameFile}.0000001.png`
    );
    const existsMusic = await checkS3FileExists(
      `${commonConstants.S3_MEDIA_CONVERT_URI}${nameFile}.mp3`
    );

    if (existsVideo && (existsThumbnail || existsThumbnail5s) && existsMusic) {
      if (existsThumbnail5s) {
        setUrlThumbnail(
          `${commonConstants.CLOUDFRONT_URL}${commonConstants.S3_MEDIA_CONVERT_URI}${nameFile}.0000001.png`
        );
      } else {
        setUrlThumbnail(
          `${commonConstants.CLOUDFRONT_URL}${commonConstants.S3_MEDIA_CONVERT_URI}${nameFile}.0000000.png`
        );
      }

      setIsUploadedStep(3);
      clearInterval(interval);
    } else {
      setCountConvert((prevCount) => {
        if (prevCount === 35) {
          clearInterval(interval);
          setIsConvertSuccess(false);
        }
        setIsUploadedStep(2);
        return prevCount + 1;
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get("name");
    const memo = data.get("memo");

    const minutes = Math.floor(duratioVideo / 60);
    const seconds = duratioVideo % 60;
    const contentsTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

    // check count time submit
    if (clickSubmit === 0) {
      // increase count time +1
      setClickSubmit(clickSubmit + 1);
      // addContentName, addMoviePlayTime, available
      setLoadingUploadCourses(true);
      userService
        .addContent(name, contentsTime, 0) //availability 1:利用可
        .then(
          //returnされたdataの確認用ログ
          async (data) => {
            if (data.data.id_content) {
              const { videoUrl, thumbnailUrl, musicUrl } =
                await changleMultiFile(nameFile, data.data.id_content);
              // Update data path video, music, mp3
              userService
                .updateContent(
                  data.data.id_content,
                  name,
                  contentsTime,
                  thumbnailUrl, // thumbnail
                  videoUrl, //video
                  musicUrl, //music
                  0, // available
                  0, // del_flg
                  memo
                )
                .then(
                  (data) => {
                    // call back list content
                    settingContent && settingContent();
                    handleClose();
                    setClickSubmit(0);
                    setLoadingUploadCourses(false);
                  },
                  (error) => {
                    // set error text if update error
                    setErrorText(error);
                    setClickSubmit(0);
                    setLoadingUploadCourses(false);
                  }
                );
            }
          },
          (error) => {
            // set error text if create error
            setClickSubmit(0);
            setErrorText(error);
            setLoadingUploadCourses(false);
          }
        );
    }
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        invisible: true,
      }}
    >
      <div
        className="course_model"
        style={{
          backgroundColor: "white",
          padding: "40px",
          outline: "none",
          border: "1px solid #bdbdbd",
          position: "absolute",
          width: "80vw",
          height: "500px",
          left: "50%",
          top: "50%",
          transform: " translate(-50%, -50%)",
        }}
      >
        <Box
          style={{
            position: "absolute",
            right: "40px",
            top: "20px",
            height: "24px",
            width: "24px",
            borderRadius: "50%",
            backgroundColor: "white",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <HighlightOffIcon style={{ fontSize: "40px", color: "gray" }} />
        </Box>
        {isUploadedStep === 1 ? (
          <Box>
            <Typography
              style={{
                fontSize: "25px",
                textAlign: "center",
                fontWeight: "bold",
                marginTop: "30px",
              }}
            >
              [Step 1]ファイルアップロード中...
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                textAlign: "center",
                color: "gray",
                marginTop: "20px",
              }}
            >
              アップロード中は、アプリを閉じないでください。
            </Typography>
            <Box
              style={{
                width: "400px",
                margin: "60px auto 0px",
              }}
            >
              <BorderLinearProgress variant="determinate" value={progress} />
              <Typography
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  color: "gray",
                  marginTop: "10px",
                }}
              >
                {progress}%
              </Typography>
            </Box>
          </Box>
        ) : isUploadedStep === 2 ? (
          <Box sx={{ textAlign: "center" }}>
            <Typography
              className="course_center_tilte"
              style={{
                fontSize: "25px",
                textAlign: "center",
                fontWeight: "bold",
                marginTop: "30px",
              }}
            >
              [Step2]コース情報の登録
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                textAlign: "center",
                color: isConvertSuccess ? "gray" : "red",
                marginTop: "10px",
              }}
            >
              {isConvertSuccess
                ? "動画を変換中です。"
                : "動画変換に失敗しました。"}
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                textAlign: "center",
                color: isConvertSuccess ? "gray" : "red",
                marginTop: "10px",
              }}
            >
              {isConvertSuccess
                ? "今暫くお待ち下さい。"
                : "キャンセルを押して、再度お試しください。"}
            </Typography>
            <Box
              style={{
                width: "60px",
                margin: "50px auto 0px",
              }}
              className="cirular_progress_container"
            >
              {isConvertSuccess ? (
                <CircularProgress
                  className="cirular_progress"
                  style={{ width: "60px", height: "60px" }}
                  disableShrink
                />
              ) : (
                <CircularProgress
                  className="cirular_progress"
                  style={{ width: "60px", height: "60px" }}
                  variant="determinate"
                  value={0}
                />
              )}
            </Box>
            <Button
              style={{
                marginTop: "30px",
                padding: "0px",
                backgroundColor: "white",
              }}
              className="course_button_right"
              onClick={handleClose}
            >
              <Tooltip title="キャンセル">
                <img
                  className="course_button_img"
                  src="/static/images/cards/btn_cancel.png"
                  alt="cancel"
                  height="45px"
                  width="auto"
                />
              </Tooltip>
            </Button>
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              height: "100%",
            }}
          >
            {!loadingUploadCourses ? (
              <>
                <Box className="course_left" style={{ width: "30%" }}>
                  <Box
                    className="course_left_container"
                    style={{
                      textAlign: "center",
                      marginTop: "80px",
                    }}
                  >
                    <Typography className="course_left_title">
                      サムネイル
                    </Typography>
                    <div>
                      <img
                        src={urlThumbnail}
                        style={{
                          width: "60%",
                          height: "240px",
                        }}
                        className="course_thumnail"
                      />
                    </div>
                  </Box>
                </Box>
                <Box style={{ width: "40%" }} className="course_center">
                  <Typography
                    className="course_center_tilte"
                    style={{
                      fontSize: "25px",
                      textAlign: "center",
                      fontWeight: "bold",
                      marginTop: "30px",
                    }}
                  >
                    [Step 2]コース情報の登録
                  </Typography>
                  <Typography
                    className="course_center_main_tilte"
                    style={{
                      textAlign: "center",
                      color: "gray",
                      marginTop: "10px",
                    }}
                  >
                    コース情報を登録してください。
                  </Typography>
                  {!isTransfer ? (
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "red",
                        marginTop: "10px",
                        fontSize: "14px",
                      }}
                      className="course_center_sub_tilte"
                    >
                      アップロードした動画を処理しています。少々お待ちください。
                    </Typography>
                  ) : (
                    ""
                  )}
                  {errorText ? (
                    <Typography
                      className="course_center_error_text"
                      style={{
                        textAlign: "center",
                        color: "red",
                        marginTop: "10px",
                        fontSize: "14px",
                      }}
                    >
                      {errorText}
                    </Typography>
                  ) : (
                    ""
                  )}
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    style={{ marginTop: "50px" }}
                    className="course_center_form"
                  >
                    <TextField
                      margin="normal"
                      inputProps={{ maxLength: 255 }}
                      required
                      fullWidth
                      id="name"
                      label="コース名 "
                      name="name"
                      variant="standard"
                      className="course_center_form_input"
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      id="memo"
                      label="メモ（最大100文字)"
                      name="memo"
                      variant="standard"
                      className="course_center_form_input"
                    />
                    <Box
                      style={{
                        display: "flex",
                        width: "100%",
                        margin: " 50px auto 0px",
                      }}
                      className="course_center_form_button"
                    >
                      <Box style={{ margin: "auto" }}>
                        <Button
                          style={{
                            padding: "0px",
                            backgroundColor: "white",
                            marginRight: "40px",
                            cursor: "pointer",
                          }}
                          disabled={clickSubmit ? true : false}
                          type="submit"
                          className="course_button_left"
                        >
                          <Tooltip title="登録する">
                            <img
                              className="course_button_img"
                              src="/static/images/cards/btn_create_course.png"
                              alt="cancel"
                              height="45px"
                              width="auto"
                            />
                          </Tooltip>
                        </Button>
                        <Button
                          style={{
                            padding: "0px",
                            backgroundColor: "white",
                            cursor: "pointer",
                          }}
                          className="course_button_right"
                          onClick={handleClose}
                        >
                          <Tooltip title="キャンセル">
                            <img
                              className="course_button_img"
                              src="/static/images/cards/btn_cancel.png"
                              alt="cancel"
                              height="45px"
                              width="auto"
                            />
                          </Tooltip>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <CircularProgress
                  className="cirular_progress"
                  style={{ width: '60px', height: '60px', margin: 'auto' }}
                  disableShrink
                />
                <Typography
                  style={{
                    fontSize: "18px",
                    bottom: "30%",
                  }}
                  position="absolute">
                    登録中です。暫くお待ち下さい...
                </Typography>
              </>
            )}

          </Box>
        )}
      </div>
    </Modal>
  );
}
