import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import { userService } from '../services/user.service';
import { authConstants } from '../constants/auth.constants';
import { commonConstants } from '../constants/common.constants';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Tooltip } from "@mui/material";


const Alert = styled('p')({
  color: 'red',
  fontSize: 14,
});

export default function Login() {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  //const [typeid, setTypeId] = useState(0);
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleSubmit = (event) => {
    event.preventDefault();

    //入力されたIDとPWを変数に代入
    const data = new FormData(event.currentTarget);
    setUserName(data.get('login_id'))
    setPassword(data.get('password'))

    //変数の確認用ログ
    // console.log({
    //   ID: username,
    //   password: password,
    // });
  };

  //IDとPWを../services/user.service のログイン処理に飛ばす
    if (username && password) {
      userService.login(username, password)
        .then(
          //returnされたdataの確認用ログ
          data => {
            console.log({
              deta: data,
            });
            //setTypeId(data.result.account_id);
            // setSuccess(data.success);

            const account_type = data.result.account_type

            console.log({
              deta: account_type,
            });

            //ログインが成功なら、画面を遷移する(遷移方法はもっと何かあるかも…)
            loginSuccess(data);
            if (account_type === 0) {
              window.location.href = '/UserManagement';
            } else if (account_type === 99) {
              window.location.href = '/RootManagement';
            }
          },
          error => {
            setMsg(error);
            setSuccess(false);
            console.log({
              errorMsg: msg,
              success: success,
            });
            //ログイン失敗時の処理は、現状特になし
            loginFailure(error);
          }
        );
    }

  //ログイン成功時
  const loginSuccess = data => ({
    type: authConstants.LOGIN_SUCCESS,
    payload: data
  });

  //ログイン失敗時
  const loginFailure = error => ({
    type: authConstants.LOGIN_FAILURE,
    payload: error
  });

  document.title = 'ログイン 屋内疑似お散歩レク';

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Stack>
          <img src="/static/images/cards/logo001.png" alt="osampo" height="100" />
          <img src="/static/images/cards/logo002.png" alt="osampo" height="200" />
        </Stack>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {success ?
            <Alert></Alert>
            : <Alert>{msg}</Alert>
          }
          <TextField
            margin="normal"
            required
            fullWidth
            id="login_id"
            label="ログインID"
            name="login_id"
            autoComplete="login_id"
            autoFocus
          />
          <FormControl required sx={{ marginTop:"15px",marginBottom:"10px",width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">パスワード</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              name="password"
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <div align="right">
            <Button type="submit">
              <Tooltip title="ログイン">
                 <img src="/static/images/cards/login.png" alt="Login" height="30" />
              </Tooltip>
            </Button>
          </div>
        </Box>
        {/* <Link to="/AccountRegistering">
          アカウントを作成
        </Link> */}
      </Box>
    </Container>
  );
}
