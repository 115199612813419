import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import { userService } from "../services/user.service";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import HeaderResetPassword from "./HeaderResetPassword";
import { Tooltip, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import PersonIcon from "@mui/icons-material/Person";

const Alert = styled("p")({
  color: "red",
  fontSize: 14,
});

const Wrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxHeight: "100vh",
});

export default function ResetPassword() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");

  const [user, setUser] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [success, setSuccess] = useState(false);
  const [validate, setValidate] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [userAfterReset, setUserAfterReset] = useState({});
  const [tokenReset, setTokenReset] = useState("");

  useEffect(() => {
    if (code) {
      // get token reset password by code
      userService.getTokenResetPassword(code).then(
        (data) => {
          const token = data.result.jwt_token;
          setTokenReset(token);
          // get info user by token
          userService.getInfoUserByToken(token).then(
            (data) => {
              setErrorText("");
              setUser(data.result);
            },
            (error) => {
              setErrorText(error);
              console.log({
                error: error,
              });
            }
          );
        },
        (error) => {
          setErrorText(error);
          console.log({
            error: error,
          });
        }
      );
    }
  }, [code]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const password_chk = passwordConfirm;

    if (!password || !password_chk) {
      setValidate(true);
    } else {
      setValidate(false);
      // cal api reset password
      userService
        .resetPassword({ token: tokenReset, password, password_chk })
        .then(
          (data) => {
            setErrorText("");
            setSuccess(true);
            setUserAfterReset(data.result);
            console.log({ data });
          },
          (error) => {
            console.log({
              error: error,
            });
            setErrorText(error);
          }
        );
    }
  };

  // go to top page
  const onClickTopPage = () => {
    sessionStorage.setItem(
      "access_token",
      JSON.stringify(userAfterReset.access_token)
    );
    sessionStorage.setItem(
      "current_account_id",
      JSON.stringify(userAfterReset.id_account)
    );
    if (userAfterReset?.account_type === 99) {
      window.location.href = '/RootManagement';
      
    } else if (userAfterReset?.account_type === 0) {
      window.location.href = '/UserManagement';

    } else {
      history.push("/");
    }
  };

  // go to login page
  const onClickLogin = () => {
    history.push("/");
  };

  return (
    <Wrapper>
      <HeaderResetPassword user={user} />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ bgcolor: deepPurple[500], width: 56, height: 56 }}>
            <PersonIcon style={{ fontSize: "35px" }} />
          </Avatar>
          <Typography fontSize="30px" fontWeight="bold" marginTop="20px">
            {success ? "パスワードが再設定されました。" : "パスワードリセット"}
          </Typography>
          <Typography
            marginTop="15px"
            fontWeight="500"
            color={user?.login_id ? "" : "red"}
          >
            {user?.login_id ?? ""}
          </Typography>
          <Typography marginTop="15px" fontWeight="500">
            {success
              ? "お使いの端末でログインしてください。"
              : "パスワードを新たに設定してください."}
          </Typography>

          <Typography marginTop="15px" color="red">
            {errorText ? errorText : ""}
          </Typography>
          {!success ? (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <FormControl
                required
                sx={{ marginTop: "15px", marginBottom: "10px", width: "100%" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  パスワード
                </InputLabel>
                <OutlinedInput
                  label="パスワード"
                  id="outlined-adornment-password"
                  name="password"
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  error={validate && !password ? true : false}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              {/* confirm passwod */}
              <FormControl
                required
                sx={{ marginTop: "15px", marginBottom: "10px", width: "100%" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  パスワード再確認＊
                </InputLabel>
                <OutlinedInput
                  label="パスワード再確認＊"
                  id="outlined-adornment-password"
                  name="password_confirm"
                  autoComplete="current-password"
                  value={passwordConfirm}
                  error={validate && !passwordConfirm ? true : false}
                  onChange={(event) => setPasswordConfirm(event.target.value)}
                  type={showPasswordConfirm ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPasswordConfirm ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Box align="right" marginTop="20px">
                <Button type="submit" style={{padding:"0px"}}>
                <Tooltip title="登録する">
                  {/* 登録 */}
                  <img
                    className="course_button_img"
                    src="/static/images/cards/btn_register.png"
                    alt="register"
                    height="45px"
                    width="auto"
                  />
                  </Tooltip>
                </Button>
                <Button
                  style={{
                    marginLeft: "20px",
                    padding:"0px"
                  }}
                  onClick={onClickLogin}
                >
                  <Tooltip title="キャンセル">
                    {/* キャンセル */}
                    <img
                      className="course_button_img"
                      src="/static/images/cards/btn_cancel.png"
                      alt="cancel"
                      height="45px"
                      width="auto"
                    />
                  </Tooltip>
                </Button>
              </Box>
            </Box>
          ) : (
            <Box align="right" marginTop="80px">
              <Button variant="contained" style={{}} onClick={onClickTopPage}>
                トップページ
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Wrapper>
  );
}
