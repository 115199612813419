var MODE_PRODUCTION = false
if (MODE_PRODUCTION) {
    var cloudfrontUrl = 'https://d2fe6gpzqil1sg.cloudfront.net/';
    var baseUrl = 'https://9v60kk4scb.execute-api.ap-northeast-1.amazonaws.com/Prod';
    var s3Bucket = 'stroll-sim-production';
    var s3AccessKey = 'AKIAUQBIS6YNADYYD2PS';
    var s3SecretAccess = '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';
} else {
    var cloudfrontUrl = 'https://www.osanpo-reku-dev.com/';
    var baseUrl = 'https://941mlr69x8.execute-api.ap-northeast-1.amazonaws.com/Prod'    
    var s3Bucket = 'stroll-sim-dev';
    var s3AccessKey = 'AKIASQSNDVDZH7IJWT6R';
    var s3SecretAccess = '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';
}

export const commonConstants = {
    CLOUDFRONT_URL: cloudfrontUrl,
    BASE_URL: baseUrl,
    S3_BUCKET: s3Bucket,
    S3_MEDIA_ORIGINAL_URI:'contents/media-original/',
    S3_MEDIA_CONVERT_URI:'contents/media-converted/',
    S3_VIDEO_URI:'contents/video/',
    S3_THUMBNAIL_URI:'contents/thumbnail/',
    S3_MUSIC_URI:'contents/music/',
    S3_REGION: 'ap-northeast-1',
    S3_ACCESS_KEY: s3AccessKey,
    S3_SECRET_ACCESS: s3SecretAccess,
}