import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { userService } from "../services/user.service";
import AccordionSummary from "@mui/material/AccordionSummary";
import NotificationTable from "./NotificationTable";
import "./style/notification.css";

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: "0px 10px 10px",
});

const StyledAccordionSummary = styled(AccordionSummary)({
  "& .MuiAccordionSummary-content": {
    margin: "0",
  },
});

export default function SelfNotification(props) {
  const { currentUserId } = props;

  const [openModel, setOpenModel] = React.useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [detail, setDetail] = useState({});

  useEffect(() => {
    handleCallListNotification();
  }, []);

  const handleCallListNotification = () => {
    userService
      .getNotificaionList()
      .then((data) => {
        setNotificationList(data.result.items);
      })
      .catch((error) => {
        console.log("error==>", error);
      });
  };

  const infoNotification = (detail) => {
    setDetail(detail);
    setOpenModel(true);
  };

  const handleOpenModel = (event) => {
    event.stopPropagation();
    setOpenModel(true);
  };

  const handleCloseModel = () => {
    setOpenModel(false);
  };

  return (
    <Box style={{ marginBottom: "10px" }}>
      <Typography variant="h6" id="tableTitle" component="div">
        お知らせ
      </Typography>
      <NotificationTable
        isRoot={false}
        notification={notificationList}
        callList={handleCallListNotification}
        callDetail={infoNotification}
      />
    </Box>
  );
}
