import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import { uploadFile } from 'react-s3';
import AWS from 'aws-sdk'
import { uploadFile } from 'react-s3';

import Header from './Header';
// import Footer from './Footer';
import SideList from './SideList';
import { userService } from '../services/user.service';
import { commonConstants } from '../constants/common.constants';
import AlertDialog from './AlertDialog';
import AddContentModal from './AddContentModal';
import ParticipantTable from './ParticipantTable';
import Participant from './Participant';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InfoUserAccount from './InfoUserAccount';
import { getCurrentIdAccount } from '../helpers/auth-header';
import Notification from './Notification'
import { right } from '@popperjs/core';

const currentAccountId = getCurrentIdAccount()

var errorMsg = "";

const StyledTableCell = styled(TableCell)({
  padding: '5px 10px',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#98b5d1',
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//動画・コーステーブル start
//連想配列
    function createCourseData(contents_name, thumbnail, contents_time, usage, watching, download, streaming, del_flg,  contents_id,use, delete_course) {
  return {
    contents_name,
    thumbnail,
    contents_time,
    usage,
    watching,
    download,
    streaming,
    del_flg,
    contents_id,
    use,
    delete: delete_course
  };
}

function usageList(id_account, id_content, usage_content, dl_flg) {
  return {
    id_account,
    id_content,
    usage_content,
    dl_flg,
  };
}

function CourseTableHead(props) {
  const headCells = [
    {
      id: 'contents_name',
      numeric: false,
      disablePadding: true,
      label: 'コース名',
    },
    {
      id: 'thumbnail',
      numeric: true,
      disablePadding: false,
      label: 'サムネイル',
    },
    {
      id: 'play_time',
      numeric: true,
      disablePadding: false,
      label: '再生時間',
    },
    {
      id: 'usage',
      numeric: true,
      disablePadding: false,
      label: '利用状況',
    },
    {
      id: 'watching',
      numeric: true,
      disablePadding: false,
      label: '視聴',
    },
    {
      id: 'download',
      numeric: true,
      disablePadding: false,
      label: 'ダウンロード',
    },
    {
      id: 'streaming',
      numeric: true,
      disablePadding: false,
      label: 'ストリーミング',
    },
  ];
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CourseTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const CourseTableToolbar = (props) => {
  const { numSelected, selected, setCourseTable, setSelected, courses} = props;

  return (
    <Box
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        height: 36,
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item xs={9}>
          <div align="right">
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={3}>
          <div align="right">
            <AddContentModal handleAddContent={props.refreshCourseData} />
            <Tooltip title="削除">
              <Button type="submit">
                <img src="/static/images/cards/delete.png" alt="delete" height="30" onClick={(event) => deleteCourseClick(event, selected, setCourseTable, setSelected, courses)} />
              </Button>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

CourseTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
  setCourseTable: PropTypes.any.isRequired,
  setSelected: PropTypes.any.isRequired,
  courses:PropTypes.any,
};

function deleteCourseClick(event, selected, setCourseTable, setSelected, courses){
  
  // 削除ボタンを押下した時点で削除されてしまうので、確認ダイアログ的なものを入れたほうが良い
  userService.updateContent(selected, null, null, null, null, null, null, 1)
  .then(
    data => {

      const courseSet = new Set(selected);
      const courseList = courses.filter(course => !courseSet.has(course.contents_id));

      console.log({
        courseList: courseList,
     });
      
      setCourseTable(courseList);
      setSelected([]);
    },
    error => {
       errorMsg = error;
      console.log({
         errorMsg: errorMsg,
      });
    }
    
  );
}


function CourseTable(props) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('contents_id');
  const courses = props.table;
  const setCourseTable = props.setCourseTable;
  const setSelectedCourse = props.setSelectedCourse;
  const selectedCourse = props.selectedCourse;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

//  const handleSelectAllClick = (event) => {
//    if (event.target.checked) {
//      const newSelecteds = courses.map((n) => n.contents_id);
//      setSelectedCourse(newSelecteds);
//      return;
//    }
//    setSelectedCourse([]);
//  };
  
  const handleClick = (event, contents_id) => {
    const selectedIndex = selectedCourse.indexOf(contents_id);
    let newSelected = [];

    console.log({
      selectedIndex: selectedIndex,
    });

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCourse, contents_id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCourse.slice(1));
    } else if (selectedIndex === selectedCourse.length - 1) {
      newSelected = newSelected.concat(selectedCourse.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCourse.slice(0, selectedIndex),
        selectedCourse.slice(selectedIndex + 1),
      );
    }

    console.log({
      newSelected: newSelected,
    });

    setSelectedCourse(newSelected);
  };

  const isSelected = (contents_id) => selectedCourse.indexOf(contents_id) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }}>
        <CourseTableToolbar numSelected={selectedCourse.length} selected={selectedCourse} setCourseTable={setCourseTable} setSelected={setSelectedCourse} refreshCourseData={props.refreshCourseData} courses={courses}/>
        <TableContainer sx={{ height: 320 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <CourseTableHead
              numSelected={selectedCourse.length}
              order={order}
              orderBy={orderBy}
              //onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              // rowCount={courses.size}
              refreshCourseData={props.refreshCourseData}
            />
            <TableBody>
              {stableSort(courses, getComparator(order, orderBy))
                .map((course, index) => {
                  const isItemSelected = isSelected(course.contents_id);
                  const labelId = `Course-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={course.contents_id}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, course.contents_id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          disabled={!course.delete}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={course.usage === "未使用" ? { background: "transparent" } : { background: "#ff9e3d" }} 
                      >
                        {course.contents_name}
                      </StyledTableCell>
                      <StyledTableCell align="right"><img src={commonConstants.CLOUDFRONT_URL + course.thumbnail} alt="osanpo" height="40" width="150"/></StyledTableCell>
                      <StyledTableCell align="right">{course.contents_time}</StyledTableCell>
                      <StyledTableCell align="right">{course.usage}</StyledTableCell>
                      <StyledTableCell align="right">
                        {course.watching ? <a href={commonConstants.CLOUDFRONT_URL + course.watching}>視聴</a>:"視聴"}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <a href={commonConstants.CLOUDFRONT_URL + course.download}>ダウンロード</a>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {course.streaming ? <a href={commonConstants.CLOUDFRONT_URL + course.streaming}>ストリーミング</a>:"ストリーミング"}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
//動画・コーステーブル end

function createCourse(courseTable, userContents, setCourseTable) {
  let courseList = courseTable.slice(0)
  for (let i = 0; i < courseTable.length; i++) {
    var usage = "";
    if (userContents !== null) {
      userContents.forEach((userContent) => {
        if(userContent.id_content == courseTable[i].contents_id) {
          switch(userContent.usage_content) {
            case 1:
              usage = "ダウンロード";
              break;
            case 2:
              usage = "ストリーミング";
              break;
            default:
              usage = "未使用";
          }
        }
      });
      if(usage == "") {
        usage = "未使用";          
      }
    } else {
      usage = "未使用";          
    }
    if (courseTable[i].del_flg == false ){
      var hogehoge = createCourseData(courseTable[i].contents_name, courseTable[i].thumbnail, courseTable[i].contents_time,usage,
        courseTable[i].watching, courseTable[i].download, courseTable[i].streaming, courseTable[i].del_flg, courseTable[i].contents_id,courseTable[i].use, courseTable[i]?.delete)
        courseList[i] = hogehoge;
    }
  }

  console.log({
    courseList: courseList,
 });

  setCourseTable(courseList)
}

/******************************************************************************************************************************************************************************/


function createHistoryData(date, startTime, steps, entryNumber, goalCriteria, content_name, movementTime, log_id) {
  return {
    date,
    startTime,
    steps,
    entryNumber,
    goalCriteria,
    content_name,
    movementTime,
    log_id,
  };
}

function HistoryTableHead(props) {
  const headCells = [
    {
      id: 'date',
      numeric: false,
      disablePadding: true,
      label: '日付',
    },
    {
      id: 'startTime',
      numeric: true,
      disablePadding: false,
      label: '開始時刻',
    },
    {
      id: 'steps',
      numeric: true,
      disablePadding: false,
      label: '歩数',
    },
    {
      id: 'entryNumber',
      numeric: true,
      disablePadding: false,
      label: '参加人数',
    },
    {
      id: 'goalCriteria',
      numeric: true,
      disablePadding: false,
      label: 'ゴール条件',
    },
    {
      id: 'content_name',
      numeric: true,
      disablePadding: false,
      label: 'コース名',
    },
    {
      id: 'movementTime',
      numeric: true,
      disablePadding: false,
      label: '運動時間',
    },
  ];

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all historys',
            }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const HistoryTableToolbar = (props) => {
  const {historys, numSelected, setHistoryTable, selectedHistory, setSelectedHistory} = props;
  return (
    <Box
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        height: 36,
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item xs={8}>
          <div align="right">
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div align="right">
            <Tooltip title="ファイル出力">
              <Button type="submit">
                <img src="/static/images/cards/file_output.png" alt="File Output" height="30"  onClick={(event) => outputCsvClick(event, selectedHistory, historys)} />
              </Button>
            </Tooltip>
            <Tooltip title="削除">
              <Button type="submit">
                <img src="/static/images/cards/delete.png" alt="delete" height="30" onClick={(event) => deleteHistoryClick(event, setHistoryTable, selectedHistory, setSelectedHistory)} />
              </Button>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

function deleteHistoryClick(event, setHistoryTable, selectedHistory, setSelectedHistory){

  //削除ボタンを押下した時点で削除されてしまうので、確認ダイアログ的なものを入れたほうが良い
  userService.deleteLogs(selectedHistory)
  .then(
    data => {
      let historyList = []
      for (let i = 0; i < data.result.items.length; i++) {


        console.log({
          length: data.result.items.length,
       });


        if (data.result.items[i].del_flg == false ){
          var hogehoge = createHistoryData(data.result.items[i].date, data.result.items[i].start_time, data.result.items[i].steps,
            data.result.items[i].member, data.result.items[i].goal_condition, data.result.items[i].course, data.result.items[i].exercise_time, data.result.items[i].log_id)
          historyList[i] = hogehoge
        }
      }

      console.log({
        historyList: historyList,
     });
      
      
     setHistoryTable(historyList);
     setSelectedHistory([]);
    },
    error => {
       errorMsg = error;
      console.log({
         errorMsg: errorMsg,
      });
    }
  );
}



function HistoryTable(props) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('log_id');

  const historys = props.table
  const setHistoryTable = props.setHistoryTable
  const selectedHistory = props.selectedHistory
  const setSelectedHistory = props.setSelectedHistory

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = historys.map((n) => n.log_id);
      setSelectedHistory(newSelecteds);
      return;
    }
    setSelectedHistory([]);
  };

  const handleClick = (event, log_id) => {
    const selectedIndex = selectedHistory.indexOf(log_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedHistory, log_id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedHistory.slice(1));
    } else if (selectedIndex === selectedHistory.length - 1) {
      newSelected = newSelected.concat(selectedHistory.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedHistory.slice(0, selectedIndex),
        selectedHistory.slice(selectedIndex + 1),
      );
    }
    setSelectedHistory(newSelected);
  };

  const isSelected = (log_id) => selectedHistory.indexOf(log_id) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }}>
        <HistoryTableToolbar historys={historys} numSelected={selectedHistory.length} setHistoryTable={setHistoryTable} selectedHistory={selectedHistory} setSelectedHistory={setSelectedHistory} />
        <TableContainer sx={{ height: 230 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <HistoryTableHead
              numSelected={selectedHistory.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={historys.length}
            />
            <TableBody>
              {stableSort(historys, getComparator(order, orderBy))
                .map((history, index) => {
                  const isItemSelected = isSelected(history.log_id);
                  const labelId = `history-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={history.log_id}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, history.log_id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {history.date}
                      </StyledTableCell>
                      <StyledTableCell align="right">{history.startTime}</StyledTableCell>
                      <StyledTableCell align="right">{history.steps}</StyledTableCell>
                      <StyledTableCell align="right">{history.entryNumber}</StyledTableCell>
                      <StyledTableCell align="right">{history.goalCriteria}</StyledTableCell>
                      <StyledTableCell align="right">{history.content_name}</StyledTableCell>
                      <StyledTableCell align="right">{history.movementTime}</StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
//利用履歴テーブル end

function AdditionInformation(props) {

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', padding: '1rem' }}>
        <List>
        <ListItem>
            <ListItemText>
              <Typography>
                ■ユーザアカウントおよびサーバ保持データの管理について
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                1. ユーザアカウントおよび、弊社サーバで保持しているデータの削除を希望する場合は、下記宛先へメールでご連絡ください。
              </Typography>
              <br />
              <Typography sx={{ marginLeft: '60px' }}>
                アルプスシステムインテグレーション株式会社 「屋内疑似お散歩レク」担当
              </Typography>
              <Typography sx={{ marginLeft: '60px' }}>
                <a href="mailto:fun@alsi.co.jp">fun@alsi.co.jp</a>
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                2. 弊社のサーバで保持しているデータは、以下になります。
              </Typography>
              <br />
              <Typography sx={{ marginLeft: '60px' }}>
                保有データ: 組織名、担当者名、電話番号、メールアドレス、ニックネーム、オリジナルコース用の動画
              </Typography>
              <Typography sx={{ marginLeft: '150px' }}>
                利用履歴(レクレーション日付、開始時刻、歩数、参加人数、ゴール条件、コース名、運動時間)
              </Typography>
              <br />
              <Typography sx={{ marginLeft: '60px' }}>
                保有期間{' '}
                <span style={{ padding: '0 5px' }}> </span>
                :削除依頼受領まで
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                3. ユーザアカウントおよび利用履歴の保持期間は、以下になります。
              </Typography>
              <br />
              <Typography sx={{ marginLeft: '60px' }}>
                削除依頼受領日の翌営業日から三日以内
              </Typography>
              <Typography sx={{ float: 'right'}}>
                以上
              </Typography>
              
            </ListItemText>
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
}


function outputCsvClick(event, selectedHistory, historys){
    //文字列型で二次元配列のデータ

   let csvData = []

   for (let i = 0; i < selectedHistory.length; i++){
    for (let r = 0; r < historys.length; r++){
      if (selectedHistory[i] === historys[r].log_id){

        console.log({
          check1: selectedHistory[i],
          check2: historys[r].log_id,
        });

        let data = []
        data.push(historys[r].date);
        data.push(historys[r].startTime);
        data.push(historys[r].steps);
        data.push(historys[r].entryNumber);
        data.push(historys[r].goalCriteria);
        data.push(historys[r].content_name);
        data.push(historys[r].movementTime);

        csvData.push(data);

        console.log({
          check3: "in",
        });
      }
    }
   }

    console.log({
      csvData: csvData,
   });

   if (csvData.length === 0){

   }

    //作った二次元配列をCSV文字列に直す。
    let csv_string  = ""; 
    for (let d of csvData) {
        csv_string += d.join(",");
        csv_string += '\r\n';
    }   

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const date = currentDate.getDate();
  
    const hour = formatTime(currentDate.getHours());
    const minute = formatTime(currentDate.getMinutes());
    const second = formatTime(currentDate.getSeconds());

  
    //ファイル名の指定
    let file_name   = year + "-" + month + "-" + date + "-" + hour + "-" + minute + "-" + second + "-Log.csv";
    var bom = new Uint8Array([0xEf, 0xBB, 0xBF]);

    //CSVのバイナリデータを作る
    let blob        = new Blob([bom, csv_string], {type: "text/csv"});
    let uri         = URL.createObjectURL(blob);

    //リンクタグを作る
    let link        = document.createElement("a");
    link.download   = file_name;
    link.href       = uri;

    //作ったリンクタグをクリックさせる
    document.body.appendChild(link);
    link.click();

    //クリックしたら即リンクタグを消す
    document.body.removeChild(link);
    link = null;
}

function formatTime(val) {
  return ("0" + val).slice(-2);
}


/******************************************************************************************************************************************************************************/

function ChangePass(props) {

  const setPassChangeDialog = props.setPassChangeDialog
  const handleSubmit = (event) => {
    event.preventDefault();

    //入力されたID、PW、確認用PWを変数に代入
    const data = new FormData(event.currentTarget);
    const password = data.get('password');
    const new_password = data.get('new_password');
    const new_password_chk = data.get('new_password_chk');

    //変数の確認用ログ
    console.log({
      password: password,
      new_password: new_password,
      new_password_chk: new_password_chk,
    });

    //ID、nickname、PW、確認用PWを../services/user.service のアカウント登録処理に飛ばす
    if (password && new_password && new_password_chk) {
      userService.changePass(password, new_password, new_password_chk)
        .then(
          //returnされたdataの確認用ログ
          data => {
            console.log({
              deta: data,
            });

            //パスワード変更が成功なら、画面を遷移する(遷移方法はもっと何かあるかも…)
            // loginSuccess(data);
            //window.location.href = '/';
             //AlertDialog();
             setPassChangeDialog(true)
             
          },
          // error => {
          //ログイン失敗時の処理は、現状特になし
          // loginFailure(error);
          // }
        );
    };
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordChk, setShowNewPasswordChk] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowNewPasswordChk = () => setShowNewPasswordChk((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: "80%", mx: "auto", pb: 2 }}>
          <Grid container
            spacing={0}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={8}>
              <FormControl required sx={{ marginTop:"15px",marginBottom:"10px",width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">現在のパスワード</InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  required
                  label="現在のパスワード"
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl required sx={{ marginTop:"15px",marginBottom:"10px",width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">変更後のパスワード</InputLabel>
                <OutlinedInput
                  id="new_password"
                  name="new_password"
                  required
                  label="変更後のパスワード"
                  autoComplete="current-password"
                  type={showNewPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl required sx={{ marginTop:"15px",marginBottom:"10px",width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">パスワード再確認</InputLabel>
                <OutlinedInput
                  id="new_password_chk"
                  name="new_password_chk"
                  required
                  label="パスワード再確認"
                  autoComplete="current-password"
                  type={showNewPasswordChk ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPasswordChk}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPasswordChk ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Button type="submit">
                <Tooltip title="変更">
                    <img src="/static/images/cards/change.png" alt="変更" height="30" />
                </Tooltip>
                </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
//パスワード変更 end


/******************************************************************************************************************************************************************************/
const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100vh',
});

const MainContainer = styled(Box)({
  margin: '20px 30px',
});

const StyledAccordionSummary = styled(AccordionSummary)({
  '& .MuiAccordionSummary-content': {
    margin: '0'
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '0px 10px 10px',
});

export default function RootManagement(props) {
  document.title = '管理者画面 屋内疑似お散歩レク';
  const [courseTable, setCourseTable] = React.useState([]);
  const [currentUserId, setCurrentUserId] = React.useState(null);
  const [userContents, setUserContents] = React.useState([]);

  const [historyTable, setHistoryTable] = React.useState([]);

  const [selectedCourse, setSelectedCourse] = React.useState([]);
  const [selectedHistory, setSelectedHistory] = React.useState([]);

  const [passChangeDialog, setPassChangeDialog] = React.useState(false);
  const [listAccount, setListAccount]=  React.useState([]);

  const getAccountList =()=>{
    userService.getAccountList().then(
      //returnされたdataの確認用ログ
      (data) => {
        if (data.result.items.length){
          setListAccount(data.result.items)
        }
      },
      (error) => {
        errorMsg = error;
        console.log({
          errorMsg: errorMsg,
        });
        //ログイン失敗時の処理は、現状特になし
        // loginFailure(error);
      }
    );
  }

  // get list account first time
  useEffect(()=>{
    getAccountList()
  },[])



  // 画面の初期表示時に呼び出し
  useEffect(() => {
    userService.getContentList(currentUserId)
    .then(
      data => {
        let courseList = []
        for (let i = 0; i < data.result.items.length; i++) {
          var usage = "未使用";
          if (data.result.items[i].use) {
            usage = "利用中"
          }
          if (data.result.items[i].del_flg == false ){
            var hogehoge = createCourseData(data.result.items[i].contents_name, data.result.items[i].contents_image, data.result.items[i].contents_time,usage,
              data.result.items[i].short_movie_url, data.result.items[i].download_url, data.result.items[i].streaming_url, data.result.items[i].del_flg, data.result.items[i].contents_id,data.result.items[i].use, data.result.items[i]?.delete)
              // courseList[i] = hogehoge;
              courseList.push(hogehoge);
          }
        }
        setCourseTable(courseList)
      },
      error => {
         errorMsg = error;
        console.log({
           errorMsg: errorMsg,
        });
      }
    );
  },[currentUserId]);

  // 左のツリーでユーザーを選択した際に呼び出し
  useEffect(() => {
    if(currentUserId !== null) {
      userService.settingContent(currentUserId)
      .then(
        data => {
          let usage = []
          for (let i = 0; i < data.result.items.length; i++) {
            var hogehoge = usageList(data.result.items[i].id_account, data.result.items[i].id_content, data.result.items[i].usage_content, data.result.items[i].dl_flg)
            usage[i] = hogehoge
          }
          setUserContents(usage);

          console.log({
            usage: usage,
         });
        },
        error => {
           errorMsg = error;
          console.log({
             errorMsg: errorMsg,
          });
        }
      );
    }
  },[currentUserId]);

  // ユーザーのコンテンツ利用状況が取得出来たら呼び出し
  useEffect(() => {
    createCourse(courseTable, userContents, setCourseTable)
  },[userContents]);


  useEffect(() => {
    // if(currentUserId !== null) {
    userService.getLogList(currentUserId??currentAccountId)
      .then(
        //returnされたdataの確認用ログ
        data => {
          console.log({
            deta: data,
          });

          let historyList = []
          for (let i = 0; i < data.result.items.length; i++) {

            if (data.result.items[i].del_flg == false ){
              var hogehoge = createHistoryData(data.result.items[i].date, data.result.items[i].start_time, data.result.items[i].steps,
                data.result.items[i].member, data.result.items[i].goal_condition, data.result.items[i].course, data.result.items[i].exercise_time, data.result.items[i].log_id)
              historyList[i] = hogehoge
            }
          }

          setHistoryTable(historyList)

        },
        error => {
          errorMsg = error;
          console.log({
            errorMsg: errorMsg,
          });
          //ログイン失敗時の処理は、現状特になし
          // loginFailure(error);
        }
      );
    // }
    //return;
  }, [currentUserId])

  function refreshCourseData() {
    setCourseTable([]);
    setUserContents([]);
    userService.getContentList()
    .then(
      data => {
        let courseList = courseTable.slice(0)
        for (let i = 0; i < data.result.items.length; i++) {
          var usage = "未使用";
          if (data.result.items[i].use) {
            usage = "利用中"
          }
          if (data.result.items[i].del_flg == false ){
            var hogehoge = createCourseData(data.result.items[i].contents_name, data.result.items[i].contents_image, data.result.items[i].contents_time,usage,
              data.result.items[i].short_movie_url, data.result.items[i].download_url, data.result.items[i].streaming_url, data.result.items[i].del_flg, data.result.items[i].contents_id,data.result.items[i].use, data.result.items[i]?.delete)
              courseList[i] = hogehoge;
          }
        }
        setCourseTable(courseList)
      },
      error => {
         errorMsg = error;
        console.log({
           errorMsg: errorMsg,
        });
      }
    );
  }

  return (
    <Wrapper>
      <CssBaseline />
      <Header />
      <MainContainer>
        <AlertDialog passChangeDialog={passChangeDialog}></AlertDialog>
        <Grid container spacing={1}>
          <Grid item xs={1.5}>
            <SideList currentChangeUser={setCurrentUserId} currentChangeSelectedCourse={setSelectedCourse} currentChangeSelectedHistory={setSelectedHistory} listAccount={listAccount} getAccountList={getAccountList}/>
          </Grid>
          <Grid item xs={10.5}>
            {/*Info user account */}
            <InfoUserAccount currentUserId={currentUserId} listAccount={listAccount} getAccountList={getAccountList} setCurrentUserId={setCurrentUserId}/>

            {/* Notification */}
            <Notification currentUserId={currentUserId}/>

            <Accordion defaultExpanded disableGutters>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ minHeight: 26 }}
              >
                <Typography>動画・コース</Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <CourseTable table={courseTable} setCourseTable={setCourseTable} selectedCourse={selectedCourse} setSelectedCourse={setSelectedCourse} refreshCourseData={refreshCourseData} />
              </StyledAccordionDetails>
            </Accordion>

            {/* Particpant */}
            <Participant currentUserId={currentUserId}/>

            <Accordion defaultExpanded disableGutters>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ minHeight: 26 }}
              >
                <Typography>利用履歴</Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <HistoryTable table={historyTable} setHistoryTable={setHistoryTable} selectedHistory={selectedHistory} setSelectedHistory={setSelectedHistory} />
              </StyledAccordionDetails>
            </Accordion>

            <Accordion defaultExpanded disableGutters>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel3a-header"
                sx={{ minHeight: 26 }}
              >
                <Typography>パスワード変更</Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <ChangePass setPassChangeDialog={setPassChangeDialog}/>
              </StyledAccordionDetails>
            </Accordion>

            <Accordion defaultExpanded disableGutters>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ minHeight: 26 }}
              >
                <Typography>退会方法</Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <AdditionInformation />
              </StyledAccordionDetails>
            </Accordion>

          </Grid>
        </Grid>
      </MainContainer>
    </Wrapper>
  );
}
