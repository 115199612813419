import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { userService } from "../services/user.service";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";

const style = {
  position: "absolute",
  top: "10%",
  left: "50%",
  transform: "translate(-50%, -10%)",
  maxWidth: "60vw",
  maxHeight: "100vh",
  bgcolor: "background.paper",
  padding: "20px 50px",
  outline: "none",
  borderRadius: "10px",
  overflow: "auto",
};

export default function AddAccountModel(props) {
  const { open, onCancel, getAccountList, detail, clearDetail, handleOpenConfirmDelete } = props;
  const [errorText, setErrorText] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [userDetail, setUserDetail] = useState({
    login_id: "",
    inactive: true,
    password: "",
    password_chk: "",
    nickname: "",
    person: "",
    tel: "",
    memo: "",
    token_expired_days: 40,
  });

  useEffect(() => {
    if (detail) {
      setUserDetail({
        ...detail,
        inactive: !detail.inactive,
        person: detail.person ?? "",
        tel: detail.tel ?? "",
        memo: detail.memo ?? "",
        password: "",
        password_chk: "",
        token_expired_days: detail.token_expired_days ?? "",
      });
    }
  }, [detail]);

  // show password
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // show confirm password
  const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword((show) => !show);

  // change value detail
  const handleChangleValue = (key, value) => {
    setUserDetail({ ...userDetail, [key]: value });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // handle create account
  const createNewAccount = (
    login_id,
    password,
    password_chk,
    nickname,
    person,
    tel,
    memo,
    inactive,
    token_expired_days
  ) => {
    userService
      .addUser(login_id, password, password_chk, nickname, person, tel, memo, inactive, token_expired_days)
      .then((data) => {
        // call back get list account again
        handleCloseModel();
        getAccountList && getAccountList();
      })
      .catch((error) => {
        setErrorText(error);
      });
  };

  // handle update account
  const updateAccount = (
    id_account,
    login_id,
    password,
    password_chk,
    nickname,
    person,
    tel,
    memo,
    inactive,
    token_expired_days,
  ) => {
    userService
      .updateUser({
        id_account,
        login_id,
        password,
        password_chk,
        nickname,
        person,
        tel,
        memo,
        inactive,
        token_expired_days
      })
      .then((data) => {
        // call back get list account again
        setErrorText("");
        onCancel && onCancel();
        getAccountList && getAccountList();
      })
      .catch((error) => {
        setErrorText(error);
      });
  };

  // submit code check create or update
  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      id_account,
      login_id,
      nickname,
      password,
      password_chk,
      person,
      tel,
      memo,
      inactive,
      token_expired_days,
    } = userDetail;
    const checkInActive = inactive? 0:1
    const tokenDays = token_expired_days == null || token_expired_days === "" ? 40 : token_expired_days;
    if (detail && detail?.login_id) {
      updateAccount(
        id_account,
        login_id,
        password,
        password_chk,
        nickname,
        person,
        tel,
        memo,
        checkInActive,
        tokenDays
      );
    } else {
      createNewAccount(
        login_id,
        nickname,
        password,
        password_chk,
        person,
        tel,
        memo,
        checkInActive,
        tokenDays
      );
    }
  };

  // clear local state and close model
  const handleCloseModel = () => {
    setUserDetail({
      login_id: "",
      inactive: true,
      password: "",
      password_chk: "",
      nickname: "",
      person: "",
      tel: "",
      memo: "",
      token_expired_days: "",
    });
    setErrorText("");
    onCancel && onCancel();
  };

// handle open model confirm delete
  const openModelDelete =()=>{
    handleOpenConfirmDelete&& handleOpenConfirmDelete()

  }

  return (
    <Modal
      open={open}
      onClose={() => onCancel && onCancel()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          {userDetail.account_id !=null? (
            <Box sx={{ position: "absolute", top: "40px", right: "40px", cursor:"pointer" }}>
              <DeleteIcon
                style={{ fontSize: "30px" }}
                onClick={openModelDelete}
              />
            </Box>
          ) : (
            ""
          )}

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            fontWeight={"bold"}
            textAlign={"center"}
          >
            {userDetail.account_id?"ユーザ編集":"ユーザー新規登録"}
          </Typography>
          <Typography
            id="modal-modal-sub-title"
            textAlign={"center"}
            fontSize="12px"
            mt="10px"
          >
            ここで登録したユーザーのみアプリでログインできます。
          </Typography>
          <Typography
            id="modal-modal-sub-title"
            textAlign={"center"}
            fontSize="12px"
          >
            ＊アプリからユーザー登録できません。
          </Typography>
          <Typography
            id="modal-modal-sub-title"
            textAlign={"center"}
            fontSize="12px"
            marginTop={"10px"}
            color={"red"}
          >
            {errorText}
          </Typography>
          {/* content */}
          <Box className="content">
            <TextField
              id="login_id"
              style={{ width: "100%", marginTop: "20px" }}
              size="small"
              label="ID : メールアドレス"
              variant="standard"
              required
              name="login_id"
              disabled={detail?.login_id ? true : false}
              value={userDetail.login_id}
              onChange={(event) =>
                handleChangleValue("login_id", event.target.value)
              }
            />
            {/* Inactive */}
            <Box style={{ width: "100%", marginTop: "30px" }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
                onChange={() =>
                  handleChangleValue("inactive", !userDetail.inactive)
                }
              >
                {userDetail.inactive ? (
                  <Checkbox
                    style={{
                      marginLeft: "-10px",
                      "& .MuiSvgIconRoot": { fontSize: 28 },
                    }}
                    checked={true}
                  />
                ) : (
                  <Checkbox
                    style={{
                      marginLeft: "-10px",
                      "& .MuiSvgIconRoot": { fontSize: 28 },
                    }}
                    checked={false}
                  />
                )}
                <Typography style={{ marginLeft: "0px", color: "gray" }}>
                  承認する
                </Typography>
              </Box>
              <Box
                style={{
                  width: "100%",
                  backgroundColor: "gray",
                  height: "1px",
                }}
              ></Box>
            </Box>

            <FormControl
              required={detail?.login_id ? false : true}
              style={{ width: "100%", marginTop: "20px" }}
              variant="standard"
            >
              <InputLabel htmlFor="standard-adornment-password">
                バスワード（英数字6文字以上）
              </InputLabel>
              <Input
                id="standard-adornment-password"
                name="password"
                inputProps={{ maxLength: 255 }}
                type={showPassword ? "text" : "password"}
                value={userDetail.password}
                onChange={(event) =>
                  handleChangleValue("password", event.target.value)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl
              required={detail?.login_id ? false : true}
              style={{ width: "100%", marginTop: "20px" }}
              variant="standard"
            >
              <InputLabel htmlFor="standard-adornment-password">
                バスワード再確認（英数字6文字以上)
              </InputLabel>
              <Input
                id="standard-adornment-password"
                name="password_chk"
                inputProps={{ maxLength: 255 }}
                type={showConfirmPassword ? "text" : "password"}
                value={userDetail.password_chk}
                onChange={(event) =>
                  handleChangleValue("password_chk", event.target.value)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleClickConfirmShowPassword}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <TextField
              id="nickname"
              style={{ width: "100%", marginTop: "20px" }}
              size="small"
              label="ニックネーム"
              variant="standard"
              required
              name="nickname"
              disabled={detail?.login_id ? true : false}
              value={userDetail.nickname}
              onChange={(event) =>
                handleChangleValue("nickname", event.target.value)
              }
            />

            <TextField
              id="person"
              style={{ width: "100%", marginTop: "20px" }}
              size="small"
              label="担当者"
              variant="standard"
              name="person"
              inputProps={{ maxLength: 255 }}
              value={userDetail.person}
              onChange={(event) =>
                handleChangleValue("person", event.target.value)
              }
            />
            <TextField
              id="tel"
              style={{ width: "100%", marginTop: "20px" }}
              size="small"
              label="電話番号"
              variant="standard"
              name="tel"
              inputProps={{ maxLength: 20 }}
              value={userDetail.tel}
              onChange={(event) =>
                handleChangleValue("tel", event.target.value)
              }
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                id="token_expired_days"
                style={{ width: "30%", marginTop: "0px" }}
                size="small"
                label="セッション期間"
                variant="standard"
                name="token_expired_days"
                inputProps={{ maxLength: 5 }} // Setting maxLength to 4 to limit input length
                value={userDetail.token_expired_days === null ? '' : userDetail.token_expired_days}
                onChange={(event) => {
                  const value = event.target.value;
                  // Validate input to be a number between 1 and 9999
                  if (value === '' || (/^\d*$/.test(value) && parseInt(value) >= 1 && parseInt(value) <= 99999)) {
                    handleChangleValue("token_expired_days", value === '' ? null : value);
                  }
                }}
              />
              <div style={{ marginLeft: "60px", marginTop: "30px", whiteSpace: "pre-line" }}>
                ※指定した周期でログイン要求します。{'\n'}
                設定変更は次のログインまで反映されません。{'\n'}
                デフォルト：40（日）
              </div>
            </div>
            <TextField
              id="memo"
              style={{ width: "100%", marginTop: "00px" }}
              size="small"
              label="メモ（最大64文字）"
              variant="standard"
              name="memo"
              inputProps={{ maxLength: 64 }}
              value={userDetail.memo}
              onChange={(event) =>
                handleChangleValue("memo", event.target.value)
              }
            />
          </Box>
          {/* Footer */}
          <Box
            className="footer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: "30px",
            }}
          >
            <Button type="submit" style={{padding:"0px"}}>
              {/* 登録 */}
              <Tooltip title="登録する">
              <img className="" src="/static/images/cards/btn_register.png" alt="btn_register" height="40px" width="auto"/>
              </Tooltip>
            </Button>
            <Button
              style={{ marginLeft: "20px", padding:"0px" }}
              onClick={handleCloseModel}
            >
              {/* 閉じる */}
              <Tooltip title="閉じる">
                 <img className="" src="/static/images/cards/btn_close.png" alt="btn_close" height="40px" width="auto"/>
              </Tooltip>
            </Button>
          </Box>
        </Box >
      
    </Modal>
  );
}
