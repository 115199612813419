import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import Box from "@mui/material/Box";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ParticipantTable from "./ParticipantTable";
import { styled } from "@mui/material/styles";
import { userService } from "../services/user.service";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { width } from "@mui/system";
import { getCurrentIdAccount } from "../helpers/auth-header";
import NotificationTable from "./NotificationTable";
import NotificationModel from "./NotificationModel";
import "./style/notification.css";

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: "0px 10px 10px",
});

const StyledAccordionSummary = styled(AccordionSummary)({
  "& .MuiAccordionSummary-content": {
    margin: "0",
  },
});

export default function Notification(props) {
  const { currentUserId } = props;

  const [openModel, setOpenModel] = React.useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [detail, setDetail] = useState({});

  useEffect(() => {
    handleCallListNotification();
  }, []);

  const handleCallListNotification = () => {
    userService
      .getNotificaionList()
      .then((data) => {
        setNotificationList(data.result.items);
      })
      .catch((error) => {
        console.log("error==>", error);
      });
  };

  const infoNotification = (detail) => {
    setDetail(detail);
    setOpenModel(true);
  };

  const handleOpenModel = (event) => {
    // event.stopPropagation();
    setDetail({});
    setOpenModel(true);
  };

  const handleCloseModel = () => {
    setDetail({});
    setOpenModel(false);
  };

  return (
    <Box>
      <Accordion defaultExpanded disableGutters>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ minHeight: 26 }}
          onClick={(event) => event.stopPropagation()}
        >
          <Typography>お知らせ</Typography>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <NotificationTable
            openModel={handleOpenModel}
            notification={notificationList}
            callList={handleCallListNotification}
            callDetail={infoNotification}
            isRoot={true}
          />
        </StyledAccordionDetails>
      </Accordion>
      <NotificationModel
        open={openModel}
        callList={handleCallListNotification}
        onCancel={handleCloseModel}
        detail={detail}
      />
    </Box>
  );
}
