import { commonConstants } from '../constants/common.constants';
import { authHeader } from '../helpers/auth-header';

export const userService = {
  login,
  logout,
  /*refresh,
  getUser,*/
  addUser,
  /*modifyUser,
  deleteUser,*/
  changePass,
  getContentList,
  getContentUse,
  getLogList,
  getAccountList,
  settingContent,
  addContent,
  updateContent,
  deleteLogs,
  update_content_use,
  getNickName,
  getParticipantList,
  addParticipant,
  updateParticipant,
  updateUser,
  getInfoAccountUser,
  getNotificaionList,
  addNotificaion,
  updateNotificaion,
  deleteNotificaion,
  deleteUser,
  getInfoUserByToken,
  resetPassword,
  getTokenResetPassword
};

const apiBase = commonConstants.BASE_URL;

function login(username, password) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  //入力されたIDとPWの情報をbody句に追加
  data.append("login_id", username);
  data.append("password", password);

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)

  //return fetch(`${apiBase}/Certification`, requestOptions)
  return fetch(`${apiBase}/certification`, requestOptions)
    .then(handleResponse)
    .then(data => {

      // トークンが取得出来た場合
      if (data.result.access_token) {
        // トークンをローカルストレージに保存する
        //sessionStorage.setItem('access_Token', JSON.stringify(data.access_Token));
        sessionStorage.setItem('access_token', JSON.stringify(data.result.access_token));
        sessionStorage.setItem('current_account_id', JSON.stringify(data.result.id_account));

      }
      /*if (data.login_id) {
          //sessionStorage.setItem('login_id', JSON.stringify(data.login_id));
          localStorage.setItem('login_id', JSON.stringify(data.login_id));
      }
      if (data.password) {
          //sessionStorage.setItem('password', JSON.stringify(data.password));
          localStorage.setItem('password', JSON.stringify(data.password));
      }*/
      return data;
    });
}


function logout() {
  // ログアウト時にはローカルストレージからトークンを削除する
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('id_user');
  sessionStorage.removeItem('id_role');
}


function addUser(username, nickname, password, password_chk, person="", tel="", memo="",inactive = 0, token_expired_days = 40) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  //入力されたID、nickname、PW、確認用PWの情報をbody句に追加
  data.append("login_id", username);
  data.append("nickname", nickname);
  data.append("password", password);
  data.append("password_chk", password_chk);
  data.append("person", person);
  data.append("tel", tel);
  data.append("memo", memo);
  data.append("inactive", inactive);
  data.append("token_expired_days", token_expired_days);

  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)

  return fetch(`${apiBase}/add_account`, requestOptions)
    .then(handleResponse)
    .then(data => {
      // (Sonix) deleted auto set access token when created user
      // トークンが取得出来た場合
      // if (data.result.access_token) {
        // トークンをローカルストレージに保存する
        // sessionStorage.setItem('access_token', JSON.stringify(data.result.access_token));
      // }
      return data;
    });
}



function changePass(password, new_password, new_password_chk) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  //入力されたID、nickname、PW、確認用PWの情報をbody句に追加
  const access_token = authHeader()

  console.log({
    access_token1: access_token,
  });

  data.append("authorization", access_token);
  data.append("password", password);
  data.append("password_cng", new_password);
  data.append("password_chk", new_password_chk);
  data.append("cng_type", 1);

  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)

  // return fetch(`${apiBase}/api/user/add`, requestOptions)
  return fetch(`${apiBase}/change_account`, requestOptions)
    .then(handleResponse)
    .then(data => {
      // トークンが取得出来た場合
      if (data.result.access_token) {

        console.log({
          access_token2: data.result.access_token,
        });

        // トークンをローカルストレージに保存する
        // sessionStorage.setItem('access_token', JSON.stringify(data.access_token));
        sessionStorage.setItem('access_token', JSON.stringify(data.result.access_token));
      }
      return data;
    });
}


function update_content_use(id_list, usage_list) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);
  data.append("id_list", id_list);
  data.append("usage_list", usage_list);

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/update_content_use`, requestOptions)
    .then(handleResponse)
    .then(data => {

      console.log({
        check1: data,
      });

      return data;
    });
}

function getNickName() {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };
 
  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/get_account_info`, requestOptions)
    .then(handleResponse)
    .then(data => {

      return data;
    });
}


function getContentList(currentUserId) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  //入力されたIDとPWの情報をbody句に追加
  data.append("authorization", access_token);
  currentUserId!==null && data.append("id_account", currentUserId);


  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };
 
  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/get_content_list`, requestOptions)
    .then(handleResponse)
    .then(data => {

      return data;
    });
}

function getContentUse() {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  //入力されたIDとPWの情報をbody句に追加
  data.append("authorization", access_token);

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };
 
  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/setting_content`, requestOptions)
    .then(handleResponse)
    .then(data => {

      return data;
    });
}

function settingContent(id) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  //入力されたIDとPWの情報をbody句に追加
  data.append("authorization", access_token);

  if (id !== null)
    data.append("account_id", id);

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };
 
  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/setting_content`, requestOptions)
    .then(handleResponse)
    .then(data => {

      console.log({
        data: data,
      });

      return data;
    });
}

function getLogList(id) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);

  if (id !== null)
    data.append("account_id", id);


  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/download_logs`, requestOptions)
    .then(handleResponse)
    .then(data => {

      return data;
    });
}

function getAccountList() {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/get_account_list`, requestOptions)
    .then(handleResponse)
    .then(data => {

      return data;
    });
}

function addContent(addContentName, addMoviePlayTime, available) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);
  data.append("contents_name", addContentName);
  data.append("contents_time", addMoviePlayTime);
  data.append("availability", available);

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/add_content`, requestOptions)
    .then(handleResponse)
    .then(data => {

      return data;
    });
}

function updateContent(contentId, contentName, moviePlayTime, thumbnailUrl, videoUrl, musicUrl, available, del_flg, memo) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);
  data.append("content_id", contentId);
  if (contentName !== null) {
    data.append("contents_name", contentName);
  }
  if (moviePlayTime !== null) {
    data.append("contents_time", moviePlayTime);
  }
  if (thumbnailUrl !== null) {
    data.append("thumbnail_url", thumbnailUrl);
  }
  if (videoUrl !== null) {
    data.append("video_url", videoUrl);
  }
  if (musicUrl !== null) {
    data.append("music_url", musicUrl);
  }
  if (available !== null) {
    data.append("availability", available);
  }
  data.append("del_flg", del_flg);

  if (memo) {
    data.append("memo", memo);
  }

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/update_content`, requestOptions)
    .then(handleResponse)
    .then(data => {

      console.log({
        data: data,
      });

      return data;
    });
}


function deleteLogs(log_id) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);
  data.append("log_id", log_id);

  console.log({
    log_id: log_id,
  });

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/delete_logs`, requestOptions)
    .then(handleResponse)
    .then(data => {

      console.log({
        data: data,
      });

      return data;
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    //APIから返ってきたdataの確認用ログ
    console.log({
      data: data,
    });

    //エラー処理
    //stub版では決め打ちなので、returnに直行(上の処理に移る)
    if (!response.ok) {
      // 認証エラー（トークン期限切れ）が帰ってきた場合
      if (response.status === 401) {
        //ログアウトする
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message);
      return Promise.reject(error);
    }
    // 応答が正常でない場合
    else if (data.code !== 'REC000') {
      const error = (data && data.message);
      return Promise.reject(error);
    }
    return data;
  });
}



function getParticipantList({id_account=""}) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);
  data.append("id_account", id_account)

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };
  return fetch(`${apiBase}/get_participant_list`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function addParticipant({name,id_account=""}) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);
  data.append("name", name);
  data.append("id_account", id_account)


  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  return fetch(`${apiBase}/add_participant`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function updateParticipant({name,id_account="", active=1, id}) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);
  data.append("id", id)
  data.append("name", name);
  data.append("id_account", id_account)
  data.append("active", active)


  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  return fetch(`${apiBase}/update_participant`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


function getInfoAccountUser({id_account}) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);
  data.append("id_account", id_account);

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };
 
  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/get_account_info`, requestOptions)
    .then(handleResponse)
    .then(data => {

      return data;
    });
}

function updateUser({id_account,login_id, nickname, password, password_chk, person="", tel="", memo="", inactive=0,token_expired_days=40}) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  //入力されたID、nickname、PW、確認用PWの情報をbody句に追加
  const access_token = authHeader()
  data.append("authorization", access_token);
  data.append("id_account", id_account);
  data.append("login_id", login_id);
  data.append("cng_type", 4);
  data.append("nickname_cng", nickname);
  data.append("password", password);
  data.append("password_chk", password_chk);
  data.append("person_cng", person);
  data.append("tel_cng", tel);
  data.append("memo_cng", memo);
  data.append("inactive", inactive);
  data.append("token_expired_days", token_expired_days);

  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)

  return fetch(`${apiBase}/change_account`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


// Notification
function getNotificaionList() {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  return fetch(`${apiBase}/get_news_list`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function addNotificaion({title,is_public, public_start, public_end, body, del_flg=0}) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);
  data.append("title", title)
  data.append("is_public", is_public)
  data.append("public_start", public_start);
  data.append("public_end", public_end)
  data.append("body", body)
  data.append("del_flg", del_flg)

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  return fetch(`${apiBase}/add_news`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


function updateNotificaion({news_id,title,is_public, public_start, public_end, body, del_flg=0}) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);
  data.append("news_id", news_id)
  data.append("title", title)
  data.append("is_public", is_public)
  data.append("public_start", public_start);
  data.append("public_end", public_end)
  data.append("body", body)
  data.append("del_flg", del_flg)

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  return fetch(`${apiBase}/update_news`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


function deleteNotificaion({news_id, del_flg=1}) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  const access_token = authHeader()
  data.append("authorization", access_token);
  data.append("news_id", news_id)
  data.append("del_flg", del_flg)

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  return fetch(`${apiBase}/update_news`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


function deleteUser(login_id) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  //入力されたID、nickname、PW、確認用PWの情報をbody句に追加
  const access_token = authHeader()
  data.append("authorization", access_token);
  data.append("login_id", login_id);
  data.append("cng_type", 3);

  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };

  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  return fetch(`${apiBase}/change_account`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


function getInfoUserByToken(token) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  data.append("authorization", token);

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };
 
  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/get_account_info`, requestOptions)
    .then(handleResponse)
    .then(data => {

      return data;
    });
}


function resetPassword({token,password, password_chk}) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  data.append("authorization", token);
  data.append("password", password);
  data.append("password_chk", password_chk);


  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };
 
  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/reset_password`, requestOptions)
    .then(handleResponse)
    .then(data => {

      return data;
    });
}


function getTokenResetPassword(uuid) {
  const fetch = require('node-fetch');
  const data = new URLSearchParams();

  data.append("uuid", uuid);

  //APIに飛ばす内容を設定する
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin',
    body: data,
  };
 
  //fetchで指定のアドレス(API)に作成した内容を飛ばす
  //APIから値が返ってきたら「handleResponse」に移る(処理はこの下)
  // return fetch(`${apiBase}/api/project/get`, requestOptions)
  return fetch(`${apiBase}/get_reset_password_token`, requestOptions)
    .then(handleResponse)
    .then(data => {

      return data;
    });
}

