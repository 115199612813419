import React, { Fragment, useState, useEffect } from 'react'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import PropTypes from 'prop-types';
import AWS from 'aws-sdk'

import { userService } from '../services/user.service';
import { commonConstants } from '../constants/common.constants';

const AddModal = styled(Modal)(({ theme }) => ({
  width: '60%',
  margin: '0 auto',
  marginTop: theme.spacing(8),
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
}));

const AddModalPaper = styled(Paper)(({ theme }) => ({
  width: '70%',
  minWidth: 600,
  margin: '0 auto',
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  overflow: 'hidden',
}));

const InputFile = styled('input')({
  display: 'none',
});

const UploadButton = styled(Button)({
  marginTop: '16px',
  marginBottom: '8px',
});

const Alert = styled('p')({
  color: 'red',
  fontSize: 14,
  textAlign: 'center',
});

const S3_BUCKET = commonConstants.S3_BUCKET;
const REGION = commonConstants.S3_REGION;

AWS.config.update({
  accessKeyId: commonConstants.S3_ACCESS_KEY, //アクセスキー入力
  secretAccessKey: commonConstants.S3_SECRET_ACCESS //シークレットアクセスキー入力
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
  httpOptions: {
    timeout: 600000
  }
})

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

function InUpload(props) {
  if (props.progress === 100) {
    props.onClose();
  }

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.isLoaded}
      >
        <CircularProgressWithLabel color="inherit" value={props.progress} />
      </Backdrop>
    </div>
  );
}

export default function AddContentModal(props) {
  const [contentId, setContentId] = useState(null);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);

  //　■コース名
  const [addContentName, setAddContentName] = useState("");

  //　■サムネイル
  const [addThumbnail, setAddThumbnail] = useState(null);
  const [addThumbnailFileName, setAddThumbnailFileName] = useState("");

  //　■動画
  const [addMovie, setAddMovie] = useState(null);
  const [addMovieFileName, setAddMovieFileName] = useState("");
  const [addMoviePlayTime, setAddMoviePlayTime] = useState(0);
  const [addMovieSrc, setAddMovieSrc] = useState("");

  //　■音声ファイル
  const [addBgm, setAddBgm] = useState(null);
  const [addBgmFileName, setAddBgmFileName] = useState("");

  //　■URL
  const [addThumbnailUrl, setAddThumbnailUrl] = useState(null);
  const [addDownloadUurl, setAddDownloadUurl] = useState(null);
  const [addBgmUrl, setAddBgmUrl] = useState(null);

  const [progress, setProgress] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState(true);

  const openAddModal = () => {
    setAddModalIsOpen(true);
  }

  const closeAddModal = () => {
    setAddModalIsOpen(false);
    setAddContentName("");
    setAddThumbnail(null);
    setAddThumbnailFileName("");
    setAddMovieFileName("");
    setAddMoviePlayTime(0);
    setAddMovieSrc("");
    setAddBgmFileName("");
  }

  const handleChangeContentName = (e) => {
    setAddContentName(e.target.value);
  }

  const handleChangeThumbnail = (e) => {
    setAddThumbnail(e.target.files[0]);
    setAddThumbnailFileName(e.target.files[0].name);
  }

  const handleChangeMovie = (e) => {
    setAddMovie(e.target.files[0]);
    setAddMovieFileName(e.target.files[0].name);
    setAddMovieSrc(URL.createObjectURL(e.target.files[0]));
    //動画の再生時間を取得
    var videoElem = document.getElementById('video');
    videoElem.addEventListener('loadedmetadata', function () {
      let time = videoElem.duration;
      let min = String(Math.floor(time % 3600 / 60)).padStart(2, '0');
      let rem = Math.floor(String(time % 60).padStart(2, '0'));

      setAddMoviePlayTime(min + ':' + rem);
    });
  }

  const handleChangeBgm = (e) => {
    setAddBgm(e.target.files[0]);
    setAddBgmFileName(e.target.files[0].name);
  }

  const handleSubmitAddContent = (event) => {
    setIsLoaded(true);
    event.preventDefault();

    if (addContentName) {
      userService.addContent(addContentName, addMoviePlayTime, 0) //availability 1:利用可
        .then(
          //returnされたdataの確認用ログ
          data => {
            setContentId(data.data.id_content);
          },
          error => {
            setMsg(error);
            setSuccess(false);
          }
        );
    }
  }

  const uploadMovie = (file, fileName) => {
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: fileName
    };

    myBucket.putObject(params)
      .on('httpUploadProgress', (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100))
      })
      .send(
        (err, data) => {
          if(err) {
            console.log(err)
            alert(err.message)
          } else if(data) {
            console.log(data)
            setAddDownloadUurl(`${commonConstants.S3_VIDEO_URI}video_${contentId}.mp4`);
          }
        }
      )
  }

  const uploadThumbnail = (file, fileName) => {
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: fileName
    };

    myBucket.putObject(params)
      .send(
        (err, data) => {
          if(err) {
            console.log(err)
            alert(err.message)
          } else if(data) {
            console.log(data)
            setAddThumbnailUrl(`${commonConstants.S3_THUMBNAIL_URI}thumbnail_${contentId}.png`)
          }
        }
      )
    }

  const uploadMusic = (file, fileName) => {
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: fileName
    };

    myBucket.putObject(params)
      .send(
        (err, data) => {
          if(err) {
            console.log(err)
            alert(err.message)
          } else if(data) {
            console.log(data)
            setAddBgmUrl(`${commonConstants.S3_MUSIC_URI}music_${contentId}.mp3`)
          }
        }
      )
  }

  useEffect(() => {
    if (contentId) {
      uploadThumbnail(addThumbnail, `${commonConstants.S3_THUMBNAIL_URI}thumbnail_${contentId}.png`);
      uploadMusic(addBgm, `${commonConstants.S3_MUSIC_URI}music_${contentId}.mp3`);
      uploadMovie(addMovie, `${commonConstants.S3_VIDEO_URI}video_${contentId}.mp4`);
      // setAddDownloadUurl(`${commonConstants.S3_VIDEO_URI}video_${contentId}.mp4`);
      // setAddThumbnailUrl(`${commonConstants.S3_THUMBNAIL_URI}thumbnail_${contentId}.png`);
      // setAddBgmUrl(`${commonConstants.S3_MUSIC_URI}music_${contentId}.mp3`);
    }
  }, [contentId]);

  useEffect(() => {
    if (addDownloadUurl !== null &&  addThumbnailUrl !== null && addBgmUrl !== null) {
      userService.updateContent(
        contentId, null, null,
        addThumbnailUrl,
        addDownloadUurl,
        addBgmUrl, 0, 0
      ).then(
        data => {
          props.handleAddContent()
        }
      )
      setIsLoaded(false)
      setAddThumbnailUrl(null)
      setAddDownloadUurl(null)
      setAddBgmUrl(null)
    }
  }, [addDownloadUurl, addThumbnailUrl, addBgmUrl]);

  return (
    <Fragment>
      <Tooltip title="追加">
        <Button type="submit" onClick={openAddModal} sx={{ mr: 1 }}>
          <img src="/static/images/cards/add.png" alt="Add" height="30" />
        </Button>
      </Tooltip>
      <AddModal
        open={addModalIsOpen}
        onClose={closeAddModal}
      >
        <AddModalPaper>
          {isLoaded &&
            <InUpload
              isLoaded={isLoaded}
              setIsLoaded={setIsLoaded}
              progress={progress}
              onClose={() => { closeAddModal() }}
              // onUpdate={() => { RootManagement() }}
              
            />
          }
          <Typography variant="headline" component="h3">
            コンテンツ追加
          </Typography>
          <Box
            component="form"
            sx={{ minWidth: 500 }}
            onSubmit={handleSubmitAddContent}
            noValidate
            autoComplete="off"
          >
            {success ?
              <Alert></Alert>
              : <Alert>{msg}</Alert>
            }
            {/* コース名 */}
            <FormControl margin="normal" fullWidth required>
              <InputLabel htmlFor="addContentName">コース名</InputLabel>
              <Input id="addContentName" name="addContentName" onChange={handleChangeContentName} value={addContentName} />
            </FormControl>

            {/* サムネイル画像 */}
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="thumbnail">サムネイル画像</InputLabel>
                  <Input type="text" id="thumbnail" name="thumbnail" readOnly value={addThumbnailFileName} required />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <InputFile type="file" id="thumbnailFileName" name="thumbnailFileName" onChange={handleChangeThumbnail} accept="image/*" />
                <label htmlFor="thumbnailFileName">
                  <UploadButton variant="contained" color="primary" component="span">
                    参照
                  </UploadButton>
                </label>
              </Grid>
            </Grid>

            {/* 動画ファイル */}
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="movie">動画ファイル</InputLabel>
                  <Input type="text" id="movie" name="movie" readOnly value={addMovieFileName} required />
                </FormControl>

              </Grid>
              <Grid item xs={2}>
                <InputFile type="file" id="movieFileName" name="movieFileName" onChange={handleChangeMovie} accept="video/*" />
                <label htmlFor="movieFileName">
                  <UploadButton variant="contained" color="primary" component="span">
                    参照
                  </UploadButton>
                </label>
              </Grid>
            </Grid>

            {/* 音声ファイル */}
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="bgm">音声ファイル</InputLabel>
                  <Input type="text" id="bgm" name="bgm" readOnly value={addBgmFileName} required />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <InputFile type="file" id="bgmFileName" name="bgmFileName" onChange={handleChangeBgm} accept="audio/*" />
                <label htmlFor="bgmFileName">
                  <UploadButton variant="contained" color="primary" component="span">
                    参照
                  </UploadButton>
                </label>
              </Grid>
            </Grid>
            <video src={addMovieSrc} id="video" display="none" height='0' width='0' type="video/mp4"></video>

            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  追加
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={closeAddModal}
                >
                  閉じる
                </Button>
              </Grid>
            </Grid>
          </Box>
        </AddModalPaper>
      </AddModal>
    </Fragment>
  );
}
