import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AppHeader from './AppHeader';
import { userService } from '../services/user.service';
import ConfirmDialog from './ConfirmDialog';
import { commonConstants } from '../constants/common.constants';
import CourseInputFileModel from './CourseInputFileModel';
import AddIcon from "@mui/icons-material/Add";
import { getCurrentIdAccount } from '../helpers/auth-header';
import SelfParticipant from './SelfParticipant';
import Modal from "@mui/material/Modal";

// get current account id after login
const currentAccountId = getCurrentIdAccount()

const StyledTableCell = styled(TableCell)({
  fontSize: '0.8rem',
  letterSpacing: '-0.05em',
  fontVariantLigatures: 'none',
  padding: '5px 10px',
  textAlign: 'center',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#98b5d1',
  },
});

const StyledSelect = styled(Select)({
  fontSize: '0.7rem',
  letterSpacing: '-0.05em',
  fontVariantLigatures: 'none',
});
const StyledMenuItem = styled(MenuItem)({
  fontSize: '0.7rem',
  letterSpacing: '-0.05em',
  fontVariantLigatures: 'none',
  padding: '5px 10px',
});
var errorMsg = '';

//動画・コーステーブル start
function createCourseData(contents_name, thumbnail, contents_time, usage, watching, download, del_flg, contents_id, is_deleted) {
  return {
    contents_name,
    thumbnail,
    contents_time,
    usage,
    watching,
    download,
    del_flg,
    contents_id,
    is_deleted
  };
}

function usageList(id_account, id_content, usage_content, dl_flg) {
  return {
    id_account,
    id_content,
    usage_content,
    dl_flg,
  };
}

function CourseTableHead(props) {
  const headCells = [
    {
      id: 'contents_name',
      label: 'コース名',
    },
    {
      id: 'thumbnail',
      label: 'サムネイル',
    },
    {
      id: 'contents_time',
      label: '再生時間',
    },
    {
      id: 'usage',
      label: '利用状況',
    },
    {
      id: 'watching',
      label: '視聴',
    },
    {
      id: 'download',
      label: 'ダウンロード',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            padding={'none'}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function decisionClick(event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg, setComfirmUpdate, setUsageChangeList){

  console.log({
    usageChangeList: usageChangeList,
  });

  let id_list = []
  let usage_list = []
  
  for (let i = 0; i < usageChangeList.length; i++) {
      id_list.push(usageChangeList[i].id_content)
      usage_list.push(usageChangeList[i].usage_content)
  }

console.log({id_list, usage_list});

  userService.update_content_use(id_list, usage_list)
  .then(
    data => {

      let usage = []
      for (let i = 0; i < data.result.items.length; i++) {
        var hogehoge = usageList(data.result.items[i].id_account, data.result.items[i].id_content, data.result.items[i].usage_content, data.result.items[i].dl_flg)
        usage[i] = hogehoge
      }
      setUserContents(usage);

      console.log({
        dedede: usage,
     });
     setComfirmUpdate(false)
     setErrorFlg(true)
     setConDialog(true)
    },
    error => {
      errorMsg = error;
      setComfirmUpdate(false)
      setErrorFlg(false)
      setErrorMsg(error)
      setConDialog(true)

      console.log({
         errorMsg: errorMsg,
      });
    }
  );
}
function CourseTable(props) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('contents_id');
  const [openInputFile, setOPenInputFile]  =React.useState(false);
  const [comfirmUpdate, setComfirmUpdate] = React.useState(false);
  
  const courses = props.table;
  const userContents = props.userContents;
  const setUserContents = props.setUserContents

  const usageChangeList = props.usageChangeList
  const setUsageChangeList = props.setUsageChangeList

  const setCourseTable = props.setCourseTable;
  const setSelectedCourse = props.setSelectedCourse;
  const selectedCourse = props.selectedCourse;

  const setConDialog = props.setConDialog;
  const setErrorFlg = props.setErrorFlg;
  const setErrorMsg = props.setErrorMsg;
  const settingContent = props.settingContent;

  const handleCloseInputFile =()=>{
    setOPenInputFile(false)
  }

  const handleOpenInputFile= ()=>{
    setOPenInputFile(true)
  }

  const checkDataBeforeUpdate =(event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg)=>{
    const index = usageChangeList.findIndex((item)=> item.usage_content ===9)
    if ( index>=0){
      setComfirmUpdate(true)
    }
    else{
      setComfirmUpdate(false)
      decisionClick(event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg, setComfirmUpdate, setUsageChangeList)
    }
  }

  const handleDelete = (event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg)=>{
    decisionClick(event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg, setComfirmUpdate, setUsageChangeList)
  }

  const onCloseConfirmDelete =()=>{
    setComfirmUpdate(false)
  }

  return (
    <Box sx={{ width: '100%' }}>
       {/* Model comfirm delete */}
       <Modal
        open={comfirmUpdate}
        onClose={onCloseConfirmDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            width: 400,
            bgcolor: "background.paper",
            border: "1px solid gray",
            borderRadius: "10px",
            outline: "none",
            p: 1,
          }}
        >
          <Typography
            id="modal-modal-title"
            fontWeight="bold"
            textAlign="center"
            variant="h7"
            component="h4"
            style={{ marginTop: "10px" }}
          >
            削除するコースがあります。
          </Typography>
          <Typography
            id="modal-modal-description"
            textAlign="center"
            variant="h7"
            component="h4"
          >
            よろしいですか？
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "70%",
              margin: "50px auto 10px",
            }}
          >
            <Button 
             style={{padding:"0px",}}
            onClick={(event)=>handleDelete(event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg)} 
            >
              {/* はい */}
              <Tooltip title="削除する">
                <img className="course_button_img" src="/static/images/cards/btn_confirm_delete_course.png" alt="cancel" height="40px" width="auto"/>
              </Tooltip>

            </Button>
            <Button
              style={{ padding:"0px" }}
              onClick={onCloseConfirmDelete}
            >
              <Tooltip title="キャンセル">
                <img className="course_button_img" src="/static/images/cards/btn_cancel.png" alt="cancel" height="40px" width="auto"/>
              </Tooltip>
            </Button>
          </Box>
        </Box>
      </Modal>
      <Grid container spacing={0} sx={{ height: 31 }}>
        <Grid item xs={5}>
          <Typography >動画・コース</Typography>
        </Grid>
        <Grid item xs={6}>
           <Box
            style={{
              display: "flex",
              alignItems:"center",
              justifyContent:"flex-end",
              marginRight:"30px"      

            }} 
          >
          <Button sx={{ p: 0, mb: 1 }}>
              <Tooltip title="コースを追加する">
              <img className="" src="/static/images/cards/btn_model_create_course.png" alt="btn_model_create_course" height="30px" width="auto" onClick={handleOpenInputFile}/>
              </Tooltip>
              </Button>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <div align="right">
            <Button type="submit" sx={{ p: 0, mb: 1 }}>
              <Tooltip title="決定">
                <img src="/static/images/cards/decision.png" alt="fixed" height="30" onClick={(event) => checkDataBeforeUpdate(event, courses, setUserContents, usageChangeList, setConDialog, setErrorFlg, setErrorMsg)} />
              </Tooltip>
            </Button>
          </div>
        </Grid>
      </Grid>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ height: 200 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 500 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <CourseTableHead />
            <TableBody>
              {stableSort(courses, getComparator(order, orderBy))
                .map((course, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={course.contents_id}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      padding="none"
                      sx={{ maxWidth: '100px' }}
                      bgcolor="transparent"
                      style={course.usage === 0 ? { background: "transparent" } : { background: "#ff9e3d" }}
                    >
                      {course.contents_name}
                    </StyledTableCell>

                    <StyledTableCell><img src={commonConstants.CLOUDFRONT_URL + course.thumbnail} alt="CourseImage" height="40" width="150"/></StyledTableCell>
                    <StyledTableCell>{course.contents_time}</StyledTableCell>

                    <StyledTableCell>
                      <StyledSelect
                        //value={["1", "2", "3"]}
                        size="small"
                        defaultValue={course.usage}
                        sx={{ minWidth: '130px' }}
                        onChange={(e) => usageChange(e, course.usage, course.contents_id, usageChangeList, setUsageChangeList)}
                      >
                        <StyledMenuItem value={0}>利用しない</StyledMenuItem>
                        <StyledMenuItem value={1}>ダウンロード</StyledMenuItem>
                        <StyledMenuItem value={2}>ストリーミング</StyledMenuItem>
                        {course.is_deleted?<StyledMenuItem value={9} >削除する</StyledMenuItem>:""}

                      </StyledSelect>
                    </StyledTableCell>

                    <StyledTableCell>
                      {course.watching ? <a href={commonConstants.CLOUDFRONT_URL + course.watching}>視聴</a>:"視聴"}
                    </StyledTableCell>
                    <StyledTableCell>
                    {course.usage === 1 ?
                        <a href={commonConstants.CLOUDFRONT_URL + course.download}>ダウンロード</a>:"ダウンロード"}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* <CourseUploadModel/> */}
      <CourseInputFileModel open={openInputFile} onClose={handleCloseInputFile} settingContent={settingContent}/>
    </Box>
  );
}



function usageChange(e, usage, content_id, usageChangeList, setUsageChangeList){
  let usageChange = []
  for (let i = 0; i < usageChangeList.length; i++) {
    if (content_id === usageChangeList[i].id_content){
      var hogehoge = usageList(usageChangeList[i].id_account, usageChangeList[i].id_content, e.target.value, usageChangeList[i].dl_flg)
    }
    else{
      var hogehoge = usageList(usageChangeList[i].id_account, usageChangeList[i].id_content, usageChangeList[i].usage_content, usageChangeList[i].dl_flg)
    }
    usageChange[i] = hogehoge
  }
  setUsageChangeList(usageChange);
}

//動画・コーステーブル end
/******************************************************************************************************************************************************************************/
//利用履歴テーブル start

function createHistoryData(date, startTime, steps, entryNumber, goalCriteria, content_name, movementTime, log_id) {
  return {
    date,
    startTime,
    steps,
    entryNumber,
    goalCriteria,
    content_name,
    movementTime,
    log_id,
  };
}

function HistoryTableHead(props) {
  const headCells = [
    {
      id: 'date',
      label: '日付',
    },
    {
      id: 'startTime',
      label: '開始時刻',
    },
    {
      id: 'steps',
      label: '歩数',
    },
    {
      id: 'entryNumber',
      label: '参加人数',
    },
    {
      id: 'goalCriteria',
      label: 'ゴール条件',
    },
    {
      id: 'content_name',
      label: 'コース名',
    },
    {
      id: 'movementTime',
      label: '運動時間',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            padding={'none'}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function HistoryTable(props) {
  const order = 'desc';
  const orderBy = 'log_id';

  const historys = props.table

  return (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ mt: 1, height: 31 }}>利用履歴</Typography>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ height: 200 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 500 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <HistoryTableHead />
            <TableBody>
              {stableSort(historys, getComparator(order, orderBy))
                .map((history, index) => {

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={history.log_id}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      padding="none"
                    >
                      {history.date}
                    </StyledTableCell>
                    <StyledTableCell>{history.startTime}</StyledTableCell>
                    <StyledTableCell>{history.steps}</StyledTableCell>
                    <StyledTableCell>{history.entryNumber}</StyledTableCell>
                    <StyledTableCell>{history.goalCriteria}</StyledTableCell>
                    <StyledTableCell>{history.content_name}</StyledTableCell>
                    <StyledTableCell>{history.movementTime}</StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

//利用履歴テーブル end
/******************************************************************************************************************************************************************************/

function AdditionInformation(props) {

  return (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ mt: 1, height: 31 }}>退会方法</Typography>
      <Paper sx={{ width: '100%' ,padding: '1rem'}}>
      <List>
        <ListItem>
            <ListItemText>
              <Typography>
                ■ユーザアカウントおよびサーバ保持データの管理について
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                1. ユーザアカウントおよび、弊社サーバで保持しているデータの削除を希望する場合は、下記宛先へメールでご連絡ください。
              </Typography>
              <br />
              <Typography sx={{ marginLeft: '60px' }}>
                アルプスシステムインテグレーション株式会社 「屋内疑似お散歩レク」担当
              </Typography>
              <Typography sx={{ marginLeft: '60px' }}>
                <a href="mailto:fun@alsi.co.jp">fun@alsi.co.jp</a>
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                2. 弊社のサーバで保持しているデータは、以下になります。
              </Typography>
              <br />
              <Typography sx={{ marginLeft: '60px' }}>
                保有データ: 組織名、担当者名、電話番号、メールアドレス、ニックネーム、オリジナルコース用の動画
              </Typography>
              <Typography sx={{ marginLeft: '150px' }}>
                利用履歴(レクレーション日付、開始時刻、歩数、参加人数、ゴール条件、コース名、運動時間)
              </Typography>
              <br />
              <Typography sx={{ marginLeft: '60px' }}>
                保有期間{' '}
                <span style={{ padding: '0 5px' }}> </span>
                :削除依頼受領まで
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                3. ユーザアカウントおよび利用履歴の保持期間は、以下になります。
              </Typography>
              <br />
              <Typography sx={{ marginLeft: '60px' }}>
                削除依頼受領日の翌営業日から三日以内
              </Typography>
              <Typography sx={{ float: 'right'}}>
                以上
              </Typography>
              
            </ListItemText>
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100vh',
});

const MainContainer = styled(Box)({
  margin: 10,
});

export default function UserManagementApp(props) {
  document.title = '屋内疑似お散歩レク';
  const [courseTable, setCourseTable] = React.useState([]);
  const [currentUserId, setCurrentUserId] = React.useState(null);
  const [userContents, setUserContents] = React.useState([]);

  const [usageChangeList, setUsageChangeList] = React.useState([]);

  const [historyTable, setHistoryTable] = React.useState([]);

  const [selectedCourse, setSelectedCourse] = React.useState([]);
  const [selectedHistory, setSelectedHistory] = React.useState([]);

  const [ConDialog, setConDialog] = React.useState(false);

  const [errorFlg, setErrorFlg] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);

  useEffect(() => {
    // Retrieve the value of 'current_account_id' from sessionStorage
    const currentUserId = sessionStorage.getItem('current_account_id');

    // Set the value of 'current_account_id' to the currentAccountId state
    setCurrentUserId(currentUserId);
  }, []);

  // 画面の初期表示時に呼び出し
  useEffect(() => {
    if (userContents.length) {
      userService.getContentList()
      .then(
        data => {
          let courseList = []
          for (let i = 0; i < data.result.items.length; i++) {
            var usage = "";
            if (userContents !== null) {
              userContents.forEach((userContent) => {
                if(userContent.id_content == data.result.items[i].contents_id) {   
                  switch(userContent.usage_content) {
                    case 1:
                      //usage = "ダウンロード";
                      usage = 1;
                      break;
                    case 2:
                      //usage = "ストリーミング";
                      usage = 2;
                      break;
                    default:
                      //usage = "未使用";
                      usage = 0;
                  }
                }
              });
              if(usage == "") {
                usage = 0;          
              }
            } else {
              usage = 0;          
            }
            if (data.result.items[i].del_flg === false) {
              var hogehoge = createCourseData(data.result.items[i].contents_name, data.result.items[i].contents_image, data.result.items[i].contents_time, usage,
                data.result.items[i].short_movie_url, data.result.items[i].download_url, data.result.items[i].del_flg, data.result.items[i].contents_id, data.result.items[i].delete)
              // courseList[i] = hogehoge;
              courseList.push(hogehoge);
            }
          }
  
          console.log({
            desuyo: courseList,
          });
          
          setCourseTable(courseList)
        },
        error => {
           //errorMsg = error;
          console.log({
             //errorMsg: errorMsg,
          });
        }
      );
    }
  },[userContents]);

  const settingContent =()=>{
    userService.settingContent()
    .then(
      data => {
        let usage = []
        for (let i = 0; i < data.result.items.length; i++) {
          var hogehoge = usageList(data.result.items[i].id_account, data.result.items[i].id_content, data.result.items[i].usage_content, data.result.items[i].dl_flg)
          usage[i] = hogehoge
        }
        setUserContents(usage);
        setUsageChangeList(usage);

        console.log({
          mogemoge: usage,
       });
      },
      error => {
         errorMsg = error;
        console.log({
           errorMsg: errorMsg,
        });
      }
    );
  }

    // 左のツリーでユーザーを選択した際に呼び出し
    useEffect(() => {
      settingContent()
    },[]);

  // ユーザーのコンテンツ利用状況が取得出来たら呼び出し
  // useEffect(() => {
  //   createCourse(courseTable, userContents, setCourseTable)
  // },[userContents]);

  useEffect(() => {
      userService.getLogList()
        .then(
          //returnされたdataの確認用ログ
          data => {
            console.log({
              deta: data,
            });

            let historyList = []
            for (let i = 0; i < data.result.items.length; i++) {

              var hogehoge = createHistoryData(data.result.items[i].date, data.result.items[i].start_time, data.result.items[i].steps,
                data.result.items[i].member, data.result.items[i].goal_condition, data.result.items[i].course, data.result.items[i].exercise_time, data.result.items[i].log_id)
              historyList[i] = hogehoge
            }

            setHistoryTable(historyList)
          },
          error => {
            errorMsg = error;
            console.log({
              errorMsg: errorMsg,
            });
            //ログイン失敗時の処理は、現状特になし
            // loginFailure(error);
          }
        );
  }, [])


  return (
    <Wrapper>
      <CssBaseline />
      <AppHeader />
      <MainContainer>
        <ConfirmDialog ConDialog={ConDialog} errorFlg={errorFlg} errorMsg={errorMsg} ></ConfirmDialog>
        <CourseTable table={courseTable} userContents={userContents} setCourseTable={setCourseTable} setUserContents={setUserContents} selectedCourse={selectedCourse} setSelectedCourse={setSelectedCourse} usageChangeList={usageChangeList} setUsageChangeList={setUsageChangeList} setConDialog={setConDialog} setErrorFlg={setErrorFlg} setErrorMsg={setErrorMsg} settingContent={settingContent}/>
        <SelfParticipant currentUserId={currentUserId} setConDialog={setConDialog} setErrorFlg={setErrorFlg} setErrorMsg={setErrorMsg}/>
        <HistoryTable table={historyTable} selectedHistory={selectedHistory} setSelectedHistory={setSelectedHistory} />
        <AdditionInformation />
      </MainContainer>
    </Wrapper>
  );
}
