import React from 'react';
import { Redirect, useLocation} from 'react-router-dom';

export default function DummyLogin() {

    const search = useLocation().search;
    const query2 = new URLSearchParams(search);
    const token = query2.get('token');
    const current_account_id = query2.get('current_account_id');

    sessionStorage.setItem('access_token', JSON.stringify(token));
    sessionStorage.setItem('current_account_id',current_account_id);


    return (
        <Redirect to="/UserManagementApp" />
    );
}