import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ParticipantTable from "./ParticipantTable";
import { userService } from "../services/user.service";
import { getCurrentIdAccount } from "../helpers/auth-header";
import { Button, Tooltip } from "@mui/material";

// get cuurent account id after login
const currentAccountId = getCurrentIdAccount();

export default function SelfParticipant(props) {
  const { currentUserId, setConDialog, setErrorFlg, setErrorMsg } = props;

  const [openInput, setOpenInput] = React.useState(false);
  const [participant, setParticipant] = useState([]);

  useEffect(() => {
    // call api get list for first time
    handleCallListParticipant();
  }, [currentUserId]);

  const handleCallListParticipant = () => {
    // call api get list and param is id_account
    userService
      .getParticipantList({ id_account: currentUserId ?? currentAccountId })
      .then((data) => {
        // set list participant again after success
        setParticipant(data.result.items);
      })
      .catch((error) => {
        console.log("error==>", error);
      });
  };

  //   open input to add
  const handleOpenInput = (event) => {
    event.stopPropagation();
    setOpenInput(true);
  };

  //   open input to add
  const handleCloseInput = () => {
    setOpenInput(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <Typography variant="h6" id="tableTitle" component="div">
          参加者
        </Typography>
        <Box
          style={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
          }}
          onClick={handleOpenInput}
        >
          <Button style={{padding:"0px"}}>
            <Tooltip title="参加者を登録する">
            <img className="" src="/static/images/cards/btn_create_participant.png" alt="btn_create_participant" height="30px" width="auto"/>
            </Tooltip>
          </Button>
        
        </Box>
      </Box>  
      <ParticipantTable
        isRoot ={false}
        currentUserId={currentUserId ?? currentAccountId}
        participant={participant}
        onOpenInput={handleOpenInput}
        openInput={openInput}
        onCloseInput={handleCloseInput}
        callList={handleCallListParticipant}
        setConDialog={setConDialog}
        setErrorFlg={setErrorFlg}
        setErrorMsg={setErrorMsg}
      />
    </Box>
  );
}
